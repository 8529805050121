import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
	Box,
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Fab,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	ListItemButton,
	Stack,
	Typography,
	IconButton,
	Menu,
	MenuItem,
} from '@mui/material';

import { Link, useNavigate } from "react-router-dom";

import {
	loadTemplateList,
	templateList,
	createTemplate,
	templateStatus,
	statuses,
} from '../../services/templates/slice';

import {
	Add as IconAdd,
	Edit as IconEdit,
	Delete as IconDelete,
	InsertDriveFile as IconInsertDriveFile,
	MoreHoriz as IconMoreHoriz,
} from '@mui/icons-material';

import { promiseContainer } from '../../components';
import { authResult, authResults } from '../../services/auth/slice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import moment from 'moment';

const TemplateList = props => {

	const propsAuthResult = useSelector(authResult);
	const navigate = useNavigate();
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const [menuAnchorData, setMenuAnchorData] = useState(null);

	const [menuId, setMenuId] = useState(null);


	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

	useEffect(() => {
		if (propsAuthResult === authResults.failed) {
			navigate('/');
		}
	}, [propsAuthResult]);

	const list = useSelector(templateList);
	const status = useSelector(templateStatus);

	const newTemplate = () => {
		dispatch(createTemplate({
			template_name: 'New Template #' + ((list || []).length + 1),
		}))
			.unwrap()
			.then(res => {
				navigate(`/templates/${res.payload}`);
			})
			.catch(err => {
				promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
					open={show}
					onClose={onDismiss}
				>
					<DialogTitle>Failed to create a new template</DialogTitle>
					<DialogContent>
						<DialogContentText>{err}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={onDismiss}>Cancel</Button>
						<Button onClick={onConfirm} autoFocus>Retry</Button>
					</DialogActions>
				</Dialog>
				)
					.then(() => { })
					.catch(() => { });
			});
	};

	useEffect(() => {
		const load = () => {
			dispatch(loadTemplateList())
				.unwrap()
				.catch(err => {
					promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
						open={show}
						onClose={onDismiss}
					>
						<DialogTitle>Failed to load the proposal template list</DialogTitle>
						<DialogContent>
							<DialogContentText>{err}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={onDismiss}>Cancel</Button>
							<Button onClick={onConfirm} autoFocus>Retry</Button>
						</DialogActions>
					</Dialog>
					)
						.then(() => {
							load();
						})
						.catch(() => { });
				});
		};
		load();
	}, []);

	const onMoreButtonClick = (e, item) => {
		setMenuAnchorEl(e.currentTarget);
		setMenuAnchorData(item);
		setMenuId(item.id);
	};

	const closeMoreMenu = () => {
		setMenuAnchorEl(null);
		setMenuAnchorData(null);
		setMenuId(null);
	};

	const onDelete = () => {
		// const deleteOne = () => {
		// 	dispatch(deleteProposal({ proposalId: menuAnchorData.proposal_id }))
		// 		.unwrap()
		// 		.catch(err => {
		// 			promiseContainer(({ onDismiss, show }) => <Dialog
		// 				open={show}
		// 				onClose={onDismiss}
		// 			>
		// 				<DialogTitle>Failed to delete</DialogTitle>
		// 				<DialogContent>
		// 					<DialogContentText>{err}</DialogContentText>
		// 				</DialogContent>
		// 				<DialogActions>
		// 					<Button onClick={onDismiss}>OK</Button>
		// 				</DialogActions>
		// 			</Dialog>
		// 			).catch(() => { });
		// 		});
		// };

		// promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
		// 	open={show}
		// 	onClose={onDismiss}
		// >
		// 	<DialogTitle>Delete Proposal</DialogTitle>
		// 	<DialogContent>
		// 		<DialogContentText>Are you sure you want to delete &ldquo;{menuAnchorData?.proposal_name}&rdquo;?</DialogContentText>
		// 	</DialogContent>
		// 	<DialogActions>
		// 		<Button onClick={onDismiss} autoFocus>No</Button>
		// 		<Button onClick={onConfirm} color="error">Delete</Button>
		// 	</DialogActions>
		// </Dialog>
		// )
		// 	.then(() => {
		// 		deleteOne();
		// 	})
		// 	.catch(() => { })
		// 	.finally(() => {
		// 		closeMoreMenu();
		// 	});
	};

	return (
		<Container sx={{ mt: 3 }}>

			<Box sx={{ width: '100%' }}>

				<Box sx={{ mb: 2 }}>

					<Fab
						variant="extended"
						color="primary"
						size="medium"
						onClick={newTemplate}
						disabled={status === statuses.saving}
					><IconAdd sx={{ mr: 1 }} />New Template</Fab>

				</Box>

			</Box>

			<Menu
				anchorEl={menuAnchorEl}
				open={Boolean(menuAnchorEl)}
				onClose={closeMoreMenu}
			>

				<MenuItem component={Link} to={`/templates/${menuId}${window.location.search || ''}`} divider>
					<ListItemIcon><IconEdit fontSize="small" /></ListItemIcon>
					<ListItemText>Edit</ListItemText>
				</MenuItem>

				<MenuItem sx={{ color: 'error.dark' }} onClick={onDelete} disabled>
					<ListItemIcon><IconDelete fontSize="small" color="error" /></ListItemIcon>
					<ListItemText>Delete</ListItemText>
				</MenuItem>

			</Menu>

			<List sx={{ pb: 16 }}>
				{
					(list || []).map((item, key) => (
						<React.Fragment key={`list_item_${key}`}>
							{key === 0 && <Divider component="li" />}

							<ListItem
								secondaryAction={
									<IconButton aria-label="more options"
										onClick={e => onMoreButtonClick(e, item)}
									>
										<IconMoreHoriz />
									</IconButton>
								}
								disablePadding
							>

								<ListItemButton component={Link}
									to={`/templates/${item.id}${window.location.search || ''}`}
								>
									<ListItemIcon sx={{ minWidth: '2rem' }}><IconInsertDriveFile /></ListItemIcon>

									<ListItemText
										sx={{ flexGrow: 1, mr: 2 }}
										primary={item?.template_name || ''}
										secondary={moment(item.date_created).format('ddd D MMM, YYYY h:mm A')}
									/>

								</ListItemButton>
							</ListItem>

							<Divider component="li" />
						</React.Fragment>
					))
				}
			</List>
		</Container>
	);
};

export default TemplateList;