export const Theme = {
	palette: {
		mode: 'light',
		primary: {
			main: '#0066ff',
			contrastText: '#fff',
		},
		primaryReversed: {
			main: '#fff',
			contrastText: '#0066ff',
		},
		secondary: {
			main: '#707070',
			contrastText: '#ffffff',
		},
		white: {
			main: '#ffffff',
			contrastText: '#0066ff',
		},
	},
	typography: {
		fontFamily: 'DM Sans',
	},
	components: {
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: '.875rem',
					fontWeight: 300,
					lineHeight: '125%',
				},
			},
		},
	},
};
