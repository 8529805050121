import axios from 'axios';

export const fetchList = () => {
	return new Promise((resolve, reject) => {
		const url = `/schools`;

		axios({
			url,
			method: 'get',
		})
			.then(res => {
				resolve(res.data || []);
			})
			.catch(err => {
				reject(err);
			});
	});
};