export const currencyInput = {
	type: 'currency',
	placeholder: "$",
	size: "small",
	sx: {
		width: '100%',
	},
};

export const percentageInput = {
	type: 'percentage',
	placeholder: "%",
	size: "small",
	sx: {
		width: '100%',
	},
};

export const numberInput = {
	type: "number",
	size: "small",
	sx: {
		width: '100%',
	},
};

export const tooltip = {
	arrow: true,
};

export const card = {
	sx: {
		borderRadius: 4,
		boxShadow: 4,
		mb: 2,
		overflow: 'hidden',
	},
};

export const cardContent = {
	sx: {
		'&, &:last-child': { 
			p: 0,
		},
	},
};

export const presetDataJson = {
	inputs: {
		sales: 1250000,
		accounts_receivable: 230000,
		tax_rate: .28,
		direct_cost: 700000,
		inventory: 110000,
		valuation_multiple: 3.5,
		overhead_expenses: 500000,
		accounts_payable: 95000,
		team_members: 6.5,
		have_other_income: 0,
		other_income: 0,

		use_customers_and_sales: 0, // 1: yes, 0: no
		repeat_customers: 1, // 1: yes, 0: no
		known_lead_gen: 0, // 1: yes, 0: no

		existing_customers: 765,
		retention_rate: .75,
		estimated_new_customers: 80,
		transactions_per_client_per_year: 1.0,
		total_customers: 765,
		// total_customers_change_by: 0, // deprecated?
		leads_per_year: 160,
		lead_conversion_rate: .5,
	},
	profit: {
		detailed_sales_drivers: 0, // 1: on, 0: off
		sales_change_by: 0,
		client_base_change_by: 0,
		gross_margin_change_by: 0,
		overhead_expenses_change_by: 0,
		retention_rate_change_by: 0,
		estimated_new_customers_change_by: 0,
		transactions_per_client_per_year_change_by: 0,
		average_transaction_value_change_by: 0,
		leads_per_year_change_by: 0,
		lead_conversion_rate_change_by: 0,
	},
	cashflow: {
		debtor_days_change_by: 0,
		inventory_days_change_by: 0,
		payable_days_change_by: 0,
	},
	return_on_investment: {
		revenue_by_team_member_change_by: 0
	}
};

export const defaultDataJson = {
	inputs: {
		sales: 0,
		accounts_receivable: 0,
		tax_rate: 0,
		direct_cost: 0,
		inventory: 0,
		valuation_multiple: 0,
		overhead_expenses: 0,
		accounts_payable: 0,
		team_members: 0,
		have_other_income: 0,
		other_income: 0,

		use_customers_and_sales: 0, // 1: yes, 0: no
		repeat_customers: 0, // 1: yes, 0: no
		known_lead_gen: 0, // 1: yes, 0: no

		existing_customers: 0,
		retention_rate: 0,
		estimated_new_customers: 0,
		transactions_per_client_per_year: 0,
		total_customers: 0,
		// total_customers_change_by: 0, // deprecated?
		leads_per_year: 0,
		lead_conversion_rate: 0,
	},
	profit: {
		detailed_sales_drivers: 0, // 1: on, 0: off
		sales_change_by: 0,
		client_base_change_by: 0,
		gross_margin_change_by: 0,
		overhead_expenses_change_by: 0,
		retention_rate_change_by: 0,
		estimated_new_customers_change_by: 0,
		transactions_per_client_per_year_change_by: 0,
		average_transaction_value_change_by: 0,
		leads_per_year_change_by: 0,
		lead_conversion_rate_change_by: 0,
	},
	cashflow: {
		debtor_days_change_by: 0,
		inventory_days_change_by: 0,
		payable_days_change_by: 0,
	},
	return_on_investment: {
		revenue_by_team_member_change_by: 0
	}
};