import { configureStore } from '@reduxjs/toolkit';
import proposalReducer from './services/proposals/slice';
import schoolReducer from './services/schools/slice';
import templateReducer from './services/templates/slice';
import authReducer from './services/auth/slice';
import presentationReducer from './services/presentations/slice';

export const store = configureStore({
	reducer: {
		proposals: proposalReducer,
		schools: schoolReducer,
		templates: templateReducer,
		auth: authReducer,
		presentations: presentationReducer,
	},
});
