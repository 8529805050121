import { useEffect, useRef } from 'react';

type HookReturnType<T> = T | undefined;

export const usePrev = <T>(value: T): HookReturnType<T> => {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref.current;
};
