import axios from 'axios';

export const load = (hash) => {
	return new Promise((resolve, reject) => {
		const url = `/presentations/${hash}`;

		axios({
			url,
			method: 'get',
		})
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err);
			});
	});
};

export const save = (hash, data) => {
	return new Promise((resolve, reject) => {
		const url = `/presentations/${hash}`;

		axios({
			url,
			method: 'PUT',
			data,
		})
			.then(() => {
				resolve(data);
			})
			.catch(err => {
				reject(err);
			});
	});
};