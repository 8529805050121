"use client";

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItemButton, ListItemText, Stack, TextField, Typography, colors } from '@mui/material';
import { loadTemplateList, templateList } from '../../services/templates/slice';
import { loadSchoolList, schoolList } from '../../services/schools/slice';
import { createProposal, proposalStatus, statuses } from '../../services/proposals/slice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import DocumentViewer from '../../components/documentViewer';
import { promiseContainer } from '../../components';
import {
	Check as IconCheck
} from '@mui/icons-material';
import { ErrorBoundary } from "react-error-boundary";

export interface INewProposal {
	show: boolean;
	onDismiss: (...args: unknown[]) => Promise<unknown>;
	onConfirm: (...args: unknown[]) => Promise<unknown>;
}

const NewProposal = (props: INewProposal): JSX.Element => {

	const [schoolId, setSchoolId] = useState<string>('');
	const [proposalName, setProposalName] = useState<string>('');
	const [templateId, setTemplateId] = useState<string>('');

	const templateViewerRef = useRef<HTMLDivElement | null>(null);

	const { show, onDismiss, onConfirm } = props;

	const status = useSelector(proposalStatus);
	const propsTemplateList = useSelector(templateList);
	// const propsTemplateStatus = useSelector(templateStatus);

	const propsSchoolList = useSelector(schoolList);
	// const propsSchoolStatus = useSelector(schoolStatus);

	useEffect(() => {
		if (propsTemplateList?.length) {
			setTemplateId(propsTemplateList?.[0]?.id);
		}
	}, [propsTemplateList]);

	useEffect(() => {
		if (templateViewerRef.current instanceof HTMLDivElement) {
			(templateViewerRef.current as HTMLDivElement).scrollTop = 0;
		}
	}, [templateId]);

	const onSetSchool = (select) => {
		setSchoolId(select.value);
		const school_name = select[select.selectedIndex].text.split('(');
		setProposalName(`Proposal for ${school_name[0]}`);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

	useEffect(() => {
		Promise.all([
			dispatch(loadTemplateList()),
			dispatch(loadSchoolList()),
		])
			.catch(errs => { });
	}, []);

	const onCreateProposal = () => {
		const payload = { 
			proposal_name: proposalName || '', 
			template_id: templateId,
			school_id: schoolId,
		};

		dispatch(createProposal(payload))
			.unwrap()
			.then(res => {
				onConfirm(res.id);
			})
			.catch(err => {

				promiseContainer(({ onConfirm, show }) => (
					<Dialog open={show} onClose={onConfirm}>
						<DialogTitle>Error</DialogTitle>
						<DialogContent>
							<DialogContentText>Failed to create the proposal. Please try it again.</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={onConfirm} color="error">Okay</Button>
						</DialogActions>
					</Dialog>
				))
					.catch(() => { });

			});
	};

	return (
		<Dialog open={show} onClose={onDismiss} fullWidth maxWidth="xl">
			<DialogContent>
				<Grid container spacing={2} sx={{ mt: 0 }}>
					<Grid item xs={12} sm={4}>
						<TextField
							select
							label="School"
							SelectProps={{
								native: true,
							}}
							onChange={(e) => onSetSchool(e.target)}
							size="small"
							sx={{ width: "100%" }}
						>
							<option></option>
							{propsSchoolList.map((school, key) => (
								<option
									key={`school_${key}`}
									value={school.school_id}
								>
									{school.school_name} (
									{school?.proposals || 0} proposals)
								</option>
							))}
						</TextField>
					</Grid>

					<Grid item xs={12} sm={8}>
						<TextField
							value={proposalName}
							onChange={(e) => setProposalName(e.target.value)}
							sx={{ display: "flex" }}
							label="Proposal name"
							size="small"
						/>
					</Grid>
				</Grid>

				<Card variant="outlined" sx={{ mt: 2 }}>
					<Grid container spacing={0}>
						<Grid item xs={12} sm={12} md={3} sx={{ position: 'relative', minHeight: '60vh' }}>
							<Box
								sx={{
									position: 'absolute',
									width: '100%',
									height: '100%',
									bgcolor: 'background.paper',
									overflowX: 'hidden',
									overflowY: 'auto',
								}}
							>
								<List component="nav" sx={{ p: 0 }}>
									{(propsTemplateList || []).map(
										(template, key) => (
											<ListItemButton
												key={`template_${key}`}
												selected={
													template.id === templateId
												}
												onClick={() =>
													setTemplateId(template.id)
												}
												divider
											>
												<ListItemText>
													<Stack
														flexDirection="row"
														alignItems="center"
														justifyContent="space-between"
													>
														<Typography sx={{ wordBreak: 'break-all' }}>
															{ template.template_name }
														</Typography>
														{
															template.id === templateId &&
															<IconCheck />
														}
													</Stack>
												</ListItemText>
											</ListItemButton>
										)
									)}
								</List>
							</Box>
						</Grid>

						<Grid item xs={12} sm={12} md={9} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} >
							<Box
								sx={{
									height: "60vh",
									overflowX: "hidden",
									overflowY: "auto",
									p: 2,
									background: colors.grey[400],
								}}
								ref={templateViewerRef}
							>
								<Box
									sx={{
										backgroundColor: colors.common.white,
									}}
								>
									<ErrorBoundary fallback={<Typography sx={{ p: 3 }}>Preview Error</Typography>}>
										{(() => {
											const template = propsTemplateList.find((template) => template.id === templateId);

											return (
												<DocumentViewer
													htmlTemplate={ template?.template_html || "" }
													data={ template?.template_data || {} }
													editableFields={false}
												/>
											);
										})()}
									</ErrorBoundary>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Card>
			</DialogContent>

			<DialogActions sx={{ px: 3, pb: 3 }} disableSpacing>
				<Button
					onClick={onDismiss}
					disabled={status === statuses.saving}
					sx={{ ml: 2 }}
				>
					Cancel
				</Button>

				<Button
					onClick={onCreateProposal}
					variant="contained"
					disabled={
						!schoolId ||
						!proposalName ||
						!templateId ||
						status === statuses.saving
					}
					sx={{ ml: 2 }}
					autoFocus
				>
					{status === statuses.saving ? (
						<CircularProgress
							size={24}
							color="primary"
						/>
					) : (
						<>Create</>
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default NewProposal;