import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import SignatureCanvas from 'react-signature-canvas';

import {
	Button,
	Container
} from "@mui/material";

// import Froalaeditor from 'froala-editor';
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/line_height.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/css/plugins/table.min.css";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/css/plugins/colors.min.css";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/css/plugins/draggable.min.css";
import "froala-editor/js/plugins/image_manager.min.js";
import "froala-editor/css/plugins/image_manager.min.css";
import "froala-editor/js/plugins/files_manager.min.js";
import "froala-editor/css/plugins/files_manager.min.css";
import "froala-editor/js/plugins/file.min.js";
import "froala-editor/css/plugins/file.min.css";
import "froala-editor/js/plugins/video.min.js";
import "froala-editor/css/plugins/video.min.css";
import { defaultConfig } from "./froalaConfig";
// plugins dependencies end

import styled from "styled-components";
import lodash from 'lodash';
import objectPath from 'object-path';


import { createPortal } from "react-dom";
import { TemplateData } from "../templates/templateData";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import { initialData } from "../templates/initialData";
// import defaultTemplate from "../templates/default.html";
// import { TemplateData } from "../templates/templateData";

interface IDocumentBuilder {
	_: unknown;
	className?: string;
	htmlTemplate: string;
	data: TemplateData;
	onContentChange: (...args: unknown[]) => void,
	onDataChange: (...args: unknown[]) => void,
}

const DocumentBuilder = (props: IDocumentBuilder): JSX.Element => {
	const [content, setContent] = useState('');
	const [data, setData] = useState<TemplateData>({});
	const [originalFields, setOriginalFields] = useState<Element[]>([]);
	const [fieldWrappers, setFieldWrappers] = useState<Record<string, Element>>({});
	const frViewRef = useRef(null);
	const signaturePadRefs = useRef({});
	const editableFields: string[] = [];

	// eslint-disable-next-line
	const { _, ...restProps } = props;

	useEffect(() => {
		if (props.htmlTemplate) {
			setContent(props.htmlTemplate);
		}
	}, []);

	useEffect(() => {
		if (props.data instanceof Object && !lodash.isEqual(props.data, data)) {
			setData(props.data);
		}
	}, [ props.data ]);

	const reducedOnDataChange = lodash.debounce(() => {
		if (props.onDataChange instanceof Function) {
			props.onDataChange(data);
		}
	}, 300);

	useEffect(() => {
		reducedOnDataChange();
		return reducedOnDataChange.cancel;
	}, [data]);

	const sanitizedContent = useMemo(() => {
		const container = document.createElement('div');
		container.innerHTML = content;
		const readyRestoreWrappers = container.querySelectorAll('span[contenteditable="false"][data-key][data-index]');

		if (!readyRestoreWrappers.length) return;

		readyRestoreWrappers.forEach((el) => {
			const index = (el as HTMLElement).dataset.index;

			if (!Number.isNaN(parseInt(index as string))) {
				el.replaceWith(originalFields[parseInt(index as string)]);
			}
		});

		return container.innerHTML;
	}, [ content ]);

	const reducedOnContentChange = lodash.debounce(() => {
		if (props.onContentChange instanceof Function) {
			props.onContentChange(sanitizedContent);
		}
	}, 1000);

	useEffect(() => {
		if (sanitizedContent) {
			reducedOnContentChange();
		}
		return reducedOnContentChange.cancel;
	}, [sanitizedContent]);

	const handleSignatureChange = (elementRef): void => {
		const canvas = elementRef._canvas;
		const wrapper = canvas.closest('[type="signature"]');
		const signData = elementRef.getSignaturePad()?._data;

		const key = wrapper?.dataset?.key;

		setData(prevState => {
			prevState = lodash.cloneDeep(prevState);
			objectPath.set(prevState, `${key}.value`, signData);
			return prevState;
		});
	};

	const rehydrateSignatures = lodash.debounce(() => {
		Object.keys(signaturePadRefs.current || {}).forEach(ref => {
			if ((data[ref]?.value as Array<unknown>)?.length) {
				const signData = lodash.cloneDeep(data[ref]?.value);
				signaturePadRefs.current[ref].fromData(signData);
			}
		});
	}, 500);

	useEffect(() => {
		rehydrateSignatures();
		return rehydrateSignatures.cancel;
	}, [data, Object.keys(signaturePadRefs.current)]);

	const handleDataChange = (event: ChangeEvent): void => {
		const target = event.target as HTMLInputElement;
		const type = (target instanceof HTMLTextAreaElement) ? 'textarea' : target.getAttribute('type');
		const key = target?.dataset?.key;

		// console.log('target', target, 'type', type, 'key', key, 'value', target.value || target.checked);

		setData(prevState => {
			prevState = lodash.cloneDeep(prevState);

			switch (type) {
				case 'text':
				case 'textarea':
					objectPath.set(prevState, `${key}.value`, target.value);
					break;

				case 'checkbox':
					objectPath.set(prevState, `${key}.value`, target.checked);
					break;

				default:
					break;
			}

			return prevState;
		});
	};

	const initializeTemplate = () => {
		setFieldWrappers({});
		// console.log("start initialization", content, data);
		const contentEl = document.querySelector('.fr-view');
		if (!contentEl) return;

		const fieldSelectors = [
			'input[type="text"][data-key], input[type="checkbox"][data-key]',
			'textarea[data-key]',
			'[type="signature"][data-key]',
		];

		const fields = contentEl.querySelectorAll(fieldSelectors.join(', '));
	
		setOriginalFields([...fields]);

		if (!fields.length) return;

		fields.forEach((el, key) => {
			if (el instanceof HTMLElement) {

				const newEl = document.createElement("span");
				newEl.setAttribute("contenteditable", "false");
				newEl.dataset.index = key.toString();

				if (el.getAttribute('type') === 'text') {
					// 
				} else if (el instanceof HTMLTextAreaElement) {
					newEl.setAttribute("type", "textarea");
				} else if (el.getAttribute('type') === 'signature') {
					newEl.setAttribute("type", "signature");
				}

				[...el.attributes].forEach(attr => {
					newEl.setAttribute(attr.nodeName, attr.nodeValue as string);
				});

				el.replaceWith(newEl);
				setFieldWrappers((prevState) => {
					const elKey = newEl.dataset?.key as string;
					if (!document.body.contains(prevState[elKey])) delete prevState[elKey];

					return {
						...prevState,
						[elKey]: newEl,
					};
				});
			}
		});
	};

	const renderFields = () => {
		// console.log('renderFields');
		return (Object.keys(fieldWrappers || {})).map((fieldKey, key) => {
			const el = fieldWrappers[fieldKey];

			if (el instanceof HTMLElement) {
				// [...el.children].forEach(child => child.remove());

				let field = <></>;
				const type = el.getAttribute('type');
				const dataKey = el.dataset?.key as string;

				// const attrs = [...el.attributes].map(attr => ({ [attr.nodeName]: attr.nodeValue }));

				let editable = true;
				if (editableFields?.length && !editableFields.includes(dataKey)) editable = false;

				switch (type) {
					case 'text':

						if (editable) {
							field = <input
								type="text"
								value={data[dataKey]?.["value"] as string || ''}
								onChange={handleDataChange}
								data-key={dataKey}
							/>;
						} else {
							field = <>{data[dataKey]?.['value'] || ''}</>;
						}
						break;

					case 'textarea':
						if (editable) {
							field = (
								<textarea
									value={data[dataKey]?.["value"] as string || ''}
									onChange={handleDataChange}
									data-key={dataKey}
									className="w-100"
									rows={8}
								></textarea>
							);
						} else {
							field = <div className="p-3 border">{data[dataKey]?.['value'] as string || ''}</div>;
						}
						break;

					case 'checkbox':
						if (editable) {
							field = <input
								type="checkbox"
								checked={data[dataKey]?.['value'] as boolean || false}
								className="form-check-input"
								onChange={handleDataChange}
								data-key={dataKey}
							/>;
						} else {
							field = <input type="checkbox" className="form-check-input" checked={data[dataKey]?.['value'] as boolean || false} disabled/>;
						}
						break;

					case 'signature':
						field = (
							<div>

								<SignatureCanvas
									penColor="#1a5cdc"

									{...editable && {
										backgroundColor: '#eeeeee',
										throttle: 20,
										minDistance: 3,
										canvasProps: {
											width: 500,
											height: 200,
											className: "d-block",
										},
										onEnd: () => handleSignatureChange(signaturePadRefs.current[dataKey]),
									}}

									{...!editable && {
										canvasProps: {
											width: 500,
											height: 200,
											className: "pointer-events-none",
										}
									}}
									ref={ el => signaturePadRefs.current[dataKey] = el }
								/>
								{
									editable &&
									<Button onClick={() => {
										signaturePadRefs.current[dataKey].clear();
										handleSignatureChange(signaturePadRefs.current[dataKey]);
									}}>Re-sign</Button>
								}

							</div>
						);
						break;

					default:
						break;
				}

				return createPortal(field, el, key.toString());
			}
			return <></>;
		});

	};

	return (
		<Container {...restProps} maxWidth="xl">
			<ToolbarContainer
				id="toolbar_container"
				className="sticky-top bg-white"
			></ToolbarContainer>

			{content && Object.keys(data) && (
				<div>
					<FroalaEditor
						config={{
							...config,
							events: {
								initialized: function () {
									// console.log("froala initialized", this);
									initializeTemplate();

									/**
									 * issues
									 * - paste into textareas
									 * - cannot enter 'enter'
									 */

									this.events.on(
										"keyup",
										function (e) {
											// console.log('keyup', document.activeElement, e);
											if (
												document.activeElement instanceof
												HTMLTextAreaElement
											) {
												e.preventDefault();
												e.stopPropagation();
												return false;
											}
										},
										true
									);

									this.events.on(
										"keydown",
										function (e) {
											if (
												!(document.activeElement instanceof HTMLInputElement) &&
												!( document.activeElement instanceof HTMLTextAreaElement) &&
												(e.keyCode === 8 || e.keyCode === 46) // 'Backspace' & 'Delete'
											) {
												// Get the current selection
												const selection: Selection | null = window.getSelection();

												// console.log('selection', selection);

												// if (selection !== null) {
												// 	const childNodes = Array.from(selection?.focusNode?.previousSibling?.childNodes || []);
												// 	childNodes.findIndex(node => {
												// 		console.log('node', node);

												// 		return node instanceof HTMLInputElement ||
												// 			node instanceof HTMLTextAreaElement;
												// 		// node.getAttribute("contenteditable") === "false"
												// 	});
												// 	e.preventDefault(); // Prevent default behavior (deleting)
												// 	e.stopPropagation();
												// 	return false;
												// }

												if (
													selection !== null &&
													selection.rangeCount > 0
												) {
													const range = selection.getRangeAt(0);
													// Check if the selection is inside a non-editable element
													if (
														range.commonAncestorContainer instanceof HTMLElement &&
														range.commonAncestorContainer.getAttribute("contenteditable") === "false"
													) {
														e.preventDefault(); // Prevent default behavior (deleting)
														e.stopPropagation();
														return false;
													}
												}
											}
										},
										true
									);
								},
								"commands.undo": function () {
									initializeTemplate();
								},
								"commands.redo": function () {
									initializeTemplate();
								},
								input: function (inputEvent) {
									if (
										inputEvent?.target instanceof HTMLInputElement ||
										inputEvent?.target instanceof HTMLTextAreaElement
									) {
										this.undo_index =
											this.undo_index - 1 < 0
												? 0
												: this.undo_index - 1;
										this.undo_stack.pop();
									}
								},
							},
						}}
						model={content}
						onModelChange={setContent}
						ref={frViewRef}
					/>
					{renderFields()}
				</div>
			)}
		</Container>
	);
};

export default DocumentBuilder;

const config = {
	...defaultConfig,
	toolbarContainer: "#toolbar_container",
	toolbarButtons: {
		moreComponent: {
			buttons: [
				"insertEditableArea",
				"insertGraphField",
				"insertStaffPicker",
				"insertStudentSummary",
				"insertAnalysisField",
			],
			buttonsVisible: 50,
		},
		...defaultConfig.toolbarButtons,
	},

	htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hr', 'span', 'canvas', 'div'],
	htmlAllowedTags: ['a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo', 'blockquote', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'header', 'hgroup', 'hr', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'keygen', 'label', 'legend', 'li', 'link', 'main', 'map', 'mark', 'menu', 'menuitem', 'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'param', 'pre', 'progress', 'queue', 'rp', 'rt', 'ruby', 's', 'samp', 'script', 'style', 'section', 'select', 'small', 'source', 'span', 'strike', 'strong', 'sub', 'summary', 'sup', 'table', 'tbody', 'td', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title', 'tr', 'track', 'u', 'ul', 'var', 'video', 'wbr'],
	htmlUntouched: true,
	enter: 1, // ENTER_P: 0, ENTER_DIV: 1, ENTER_BR: 2
	documentReady: true,
	pastePlain: true,
	dragInline: false,
};

const ToolbarContainer = styled.div``;
