import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import SignatureCanvas from 'react-signature-canvas';

import lodash from 'lodash';
import objectPath from "object-path";

import { createPortal } from "react-dom";
import { Button } from "@mui/material";
import { FieldType, FieldValue, TemplateData } from "../templates/templateData";

/**
 * @param {string} htmlTemplate - HTML template
 * @param {TemplateData} data - Template data
 * @param {string[]} editableFields - Enabled fileds (key). Pass `false` to disable all fields.
 * @param {Function} onChange - Triggers when the document data have changed.
 */
interface IDocumentViewer {
	htmlTemplate: string;
	data: TemplateData;
	editableFields?: string[] | false;
	onChange?: (data: TemplateData) => void;
	jsonDidAutoComplete: (data: TemplateData) => void;
}

/**
 * 
 * @param props 
 * @returns 
 */
const DocumentViewer = (props: IDocumentViewer): JSX.Element => {

	const [data, setData] = useState<TemplateData>(props.data || {});
	const [fieldWrappers, setFieldWrappers] = useState<Record<string, Element>>({});
	const frViewRef = useRef(null);
	const signaturePadRefs = useRef({});

	useEffect(() => {
		if (Object.keys(props.data || {}).length && !lodash.isEqual(props.data, data)) setData(props.data);
	}, [ props.data ]);

	const reducedOnChange = lodash.debounce(() => {
		if (props.onChange instanceof Function) {
			props.onChange(data);
		}
	}, 300);

	useEffect(() => {
		if (props.editableFields !== false) {
			reducedOnChange();
			return reducedOnChange.cancel;
		}
	}, [ data ]);

	const handleSignatureChange = (elementRef): void => {
		// console.log('handleSignatureChange', elementRef);
		const canvas = elementRef._canvas;
		const wrapper = canvas.closest('[type="signature"]');
		const signData = elementRef.getSignaturePad()?._data;

		const key = wrapper?.dataset?.key;

		setData(prevState => {
			prevState = lodash.cloneDeep(prevState);
			objectPath.set(prevState, `${key}.value`, signData);
			return prevState;
		});
	};

	const rehydrateSignatures = lodash.debounce(() => {
		Object.keys(signaturePadRefs.current || {}).forEach(ref => {
			if ((data[ref]?.value as Array<unknown>)?.length) {
				const signData = lodash.cloneDeep(data[ref]?.value);
				signaturePadRefs.current[ref].fromData(signData);
			}
		});
	}, 500);

	useEffect(() => {
		rehydrateSignatures();
		return rehydrateSignatures.cancel;
	}, [data, Object.keys(signaturePadRefs.current)]);

	const handleDataChange = (event: ChangeEvent): void => {
		const target = event.target as HTMLInputElement;
		const type = (target instanceof HTMLTextAreaElement) ? 'textarea' : target.getAttribute('type');
		const key = target?.dataset?.key;

		// console.log('target', target, 'type', type, 'key', key, 'value', target.value || target.checked);

		setData(prevState => {
			prevState = lodash.cloneDeep(prevState);

			switch (type) {
				case 'text':
				case 'textarea':
					objectPath.set(prevState, `${key}.value`, target.value);
					break;

				case 'checkbox':
					objectPath.set(prevState, `${key}.value`, target.checked);
					break;

				default:
					break;
			}

			return prevState;
		});
	};

	const initializeTemplate = () => {

		const contentEl = frViewRef.current as HTMLElement | null;
		if (!contentEl) return;

		const fieldSelectors = [
			'input[type="text"][data-key], input[type="checkbox"][data-key]',
			'textarea[data-key]',
			'[type="signature"][data-key]',
		];

		const fields = contentEl.querySelectorAll(fieldSelectors.join(', '));

		if (!fields.length) return;

		let didAutoComplete = false;
		const dataTemp = lodash.cloneDeep(data);

		fields.forEach((el, key) => {

			if (el instanceof HTMLElement) {

				const newEl = document.createElement("span");
				newEl.setAttribute("contenteditable", "false");
				newEl.dataset.index = key.toString();

				if (el.getAttribute('type') === 'text') {
					// 
				} else if (el instanceof HTMLTextAreaElement) {
					newEl.setAttribute("type", "textarea");
				} else if (el.getAttribute('type') === 'signature') {
					newEl.setAttribute("type", "signature");
				}

				[...el.attributes].forEach(attr => {
					newEl.setAttribute(attr.nodeName, attr.nodeValue as string);
				});

				if (newEl?.dataset?.key && !dataTemp.hasOwnProperty(newEl?.dataset?.key)) {
					didAutoComplete = true;
					let itemValue: FieldValue = '';
					switch (newEl.getAttribute('type')) {
						case 'text':
						case 'textarea':
						default:
							itemValue = '';
							break;

						case 'checkbox':
							itemValue = false;
							break;

						case 'signature':
							itemValue = [];
							break;
					}

					dataTemp[newEl?.dataset?.key] = {
						type: newEl.getAttribute('type') as FieldType,
						value: itemValue,
					}
				}

				el.replaceWith(newEl);

				setFieldWrappers((prevState) => {
					const elKey = newEl.dataset?.key as string;
					return {
						...prevState,
						[elKey]: newEl,
					};
				});
			}
		});

		setData(dataTemp);
		if (didAutoComplete && props.jsonDidAutoComplete instanceof Function) {
			props.jsonDidAutoComplete(dataTemp);
		}

	};

	const renderFields = () => {
		// console.log('renderFields');
		return (Object.keys(fieldWrappers || {})).map((fieldKey, key) => {
			const el = fieldWrappers[fieldKey];

			if (el instanceof HTMLElement) {
				// [...el.children].forEach(child => child.remove());

				let field = <></>;
				const type = el.getAttribute('type');
				const dataKey = el.dataset?.key as string;

				// const attrs = [...el.attributes].map(attr => ({ [attr.nodeName]: attr.nodeValue }));

				let editable = true;
				if (props.editableFields === false || (props.editableFields?.length && !props.editableFields.includes(dataKey))) editable = false;

				switch (type) {
					case 'text':

						if (editable) {
							field = <input
								type="text"
								value={data[dataKey]?.["value"] as string || ''}
								onChange={handleDataChange}
								data-key={dataKey}
							/>;
						} else {
							field = <>{data[dataKey]?.['value'] || ''}</>;
						}
						break;

					case 'textarea':
						if (editable) {
							field = (
								<textarea
									value={data[dataKey]?.["value"] as string || ''}
									onChange={handleDataChange}
									data-key={dataKey}
									className="w-100"
									rows={8}
								></textarea>
							);
						} else {
							field = <div className="p-3 border">{data[dataKey]?.['value'] as string || ''}</div>;
						}
						break;

					case 'checkbox':
						if (editable) {
							field = <input
								type="checkbox"
								checked={data[dataKey]?.['value'] as boolean || false}
								className="form-check-input"
								onChange={handleDataChange}
								data-key={dataKey}
							/>;
						} else {
							field = <input type="checkbox" className="form-check-input" checked={data[dataKey]?.['value'] as boolean || false} disabled/>;
						}
						break;

					case 'signature':
						field = (
							<div>
								<SignatureCanvas
									penColor="#1a5cdc"
									clearOnResize={false}

									{...editable && {
										backgroundColor: '#eeeeee',
										throttle: 20,
										minDistance: 3,
										canvasProps: {
											width: 500,
											height: 200,
											className: "d-block",
										},
										onEnd: () => handleSignatureChange(signaturePadRefs.current[dataKey]),
									}}

									{...!editable && {
										canvasProps: {
											width: 500,
											height: 200,
											className: "pointer-events-none",
										}
									}}
									ref={ el => signaturePadRefs.current[dataKey] = el }
								/>
								{
									editable &&
									<Button onClick={() => {
										signaturePadRefs.current[dataKey].clear();
										handleSignatureChange(signaturePadRefs.current[dataKey]);
									}}>Re-sign</Button>
								}
							</div>
						);
						break;

					default:
						break;
				}

				// console.log('el', el, 'existing in dom', document.contains(el));

				return createPortal(field, el, key.toString());
			}
			return <></>;
		});

	};

	useEffect(initializeTemplate, [
		props.htmlTemplate,
	]);

	const createMarkup = (html: string) => ({ __html: html });

	return (
		<div>
			<div
				className="fr-view p-5"
				dangerouslySetInnerHTML={createMarkup(props.htmlTemplate)}
				ref={frViewRef}
			/>
			{ renderFields() }
		</div>
	);
};

export default DocumentViewer;