import axios from 'axios';

export const login = (data) => {
	return new Promise((resolve, reject) => {
		const url = `/auth/token`;

		axios({
			url,
			method: 'post',
			data: data
		})
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err);
			});
	});
};

export const retrieve = (data) => {
	return new Promise((resolve, reject) => {
		const url = `/auth/retrieve`;

		axios({
			url,
			method: 'post',
			data: data
		})
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err);
			});
	});
};


export const expireToken = () => {
	return new Promise((resolve, reject) => {
		const url = `/auth/logout`;

		axios({
			url,
			method: 'delete',
		})
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err);
			});
	});
};