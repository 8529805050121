import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { 
	fetchList, 
	create,
	deleteOne,
	load,
	save,
	duplicate,
	createLink,
} from "./api";

export const statuses = {
	idle: 'IDLE',
	loading: 'LOADING',
	loaded: 'LOADED',
	saving: 'SAVING',
	deleting: 'DELETING',
	saved: 'SAVED',
	error: 'ERROR',
};

const initialState = {
	proposal: {},
	list: [],
	status: statuses.idle,
};

export const loadProposalList = createAsyncThunk(
	'proposal/list',
	async (_, { rejectWithValue }) => {
		try{
			const response = await fetchList();
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);


export const createProposal = createAsyncThunk(
	'proposal/create',
	/**  
	 * @param data {object}
	 */
	async (data, { rejectWithValue }) => {
		try{
			const response = await create(data);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const sendProposal = createAsyncThunk(
	'proposal/send',
	/**  
	 * @param data {object}
	 */
	async ({proposal_id, data}, { rejectWithValue }) => {
		try{
			const response = await send(proposal_id, data);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const duplicateProposal = createAsyncThunk(
	'proposal/duplicate',
	async ({ proposalId }, { rejectWithValue }) => {
		try{
			const response = await duplicate(proposalId);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const deleteProposal = createAsyncThunk(
	'proposal/delete',
	/**
	 * @param {object} param0 
	 */
	async ({ proposalId }, { rejectWithValue }) => {
		try{
			const response = await deleteOne(proposalId);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const saveProposal = createAsyncThunk(
	'proposal/save',
	async ({ proposalId, data }, { rejectWithValue }) => {
		try{
			const response = await save(proposalId, data);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const loadProposal = createAsyncThunk(
	'proposal/load',
	async ({ proposalId }, { rejectWithValue }) => {
		try{
			const response = await load(proposalId);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const newLink = createAsyncThunk(
	'proposal/newLink',
	/**  
	 * @param data {object}
	 */
	async ({ proposalId, data }, { rejectWithValue }) => {
		try{
			const response = await createLink(proposalId, data);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);


export const removeLink = createAsyncThunk(
	'proposal/removeLink',
	/**  
	 * @param link_id {integet}
	 */
	async ({ proposalId, linkId }, { rejectWithValue }) => {
		try{
			const response = await deleteLink(proposalId, linkId);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);


export const proposalSlice = createSlice({
	name: 'proposals',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(loadProposalList.pending, state => {
				state.status = statuses.loading;
			}).addCase(loadProposalList.fulfilled, (state, action) => {
				state.status = statuses.loaded;
				state.list = action.payload;
			}).addCase(loadProposalList.rejected, state => {
				state.status = statuses.error;
			})
			
			.addCase(createProposal.pending, state => {
				state.status = statuses.saving;
			}).addCase(createProposal.fulfilled, (state, action) => {
				state.status = statuses.saved;
				state.list = [
					action.payload,
					...state.list,
				];
			}).addCase(createProposal.rejected, state => {
				state.status = statuses.error;
			})

			.addCase(deleteProposal.pending, state => {
				state.status = statuses.deleting;
			}).addCase(deleteProposal.fulfilled, (state, action) => {
				state.status = statuses.saved;
				state.list = (state.list || []).filter(item => item.id != action.payload);
			}).addCase(deleteProposal.rejected, state => {
				state.status = statuses.error;
			})
			
			.addCase(loadProposal.pending, state => {
				state.status = statuses.loading;
				state.proposal = {};
			}).addCase(loadProposal.fulfilled, (state, action) => {
				state.proposal = action.payload;
				state.status = statuses.loaded;
			}).addCase(loadProposal.rejected, state => {
				state.status = statuses.error;
			})
			
			.addCase(saveProposal.pending, state => {
				state.status = statuses.saving;
			}).addCase(saveProposal.fulfilled, (state, action) => {
				state.status = statuses.saved;
				state.proposal = { ...state.proposal, ...action.payload || {} };
			}).addCase(saveProposal.rejected, state => {
				state.status = statuses.error;
			})
			
			.addCase(duplicateProposal.pending, state => {
				state.status = statuses.saving;
			}).addCase(duplicateProposal.fulfilled, (state) => {
				state.status = statuses.saved;
			}).addCase(duplicateProposal.rejected, state => {
				state.status = statuses.error;
			})

			.addCase(newLink.pending, state => {
				state.status = statuses.saving;
			}).addCase(newLink.fulfilled, (state, action) => {
				state.status = statuses.saved;
				state.proposal.links = [
				...state.proposal.links || [],
				action.payload,
				];
			}).addCase(newLink.rejected, state => {
				state.status = statuses.error;
			});
	}
});

// export const { loadProposalList } = calculationSlice.actions;

export const proposalList = (state) => state.proposals?.list;
export const proposal = (state) => state.proposals?.proposal;
export const proposalStatus = (state) => state.proposals?.status;

export default proposalSlice.reducer;
