import axios from 'axios';

export const featchThreadAPI = (threadId) => {
	return new Promise((resolve, reject) => {
		const url = `/threads/${threadId}`;

		axios({
			url,
			method: 'get',
		})
			.then(res => {
				resolve(res.data || {});
			})
			.catch(err => {
				reject(err);
			});
	});
};
export const fetchListAPI = () => {
	return new Promise((resolve, reject) => {
		const url = `/threads`;

		axios({
			url,
			method: 'get',
		})
			.then(res => {
				resolve(res.data || []);
			})
			.catch(err => {
				reject(err);
			});
	});
};

export const createThreadAPI = (data) => {
	return new Promise((resolve, reject) => {
		const url = `/threads`;

		axios({
			url,
			method: 'POST',
			data: data,
		})
			.then(res => {
				resolve(res.data.id || 0);
			})
			.catch(err => {
				reject(err);
			});
	});
};

export const sendMessageAPI = (data) => {
	return new Promise((resolve, reject) => {
		const url = `/threads/${data.thread_id}/reply`;

		axios({
			url,
			method: 'POST',
			data: data,
		})
			.then(res => {
				resolve(res.data.id || 0);
			})
			.catch(err => {
				reject(err);
			});
	});
};
export const saveNoteAPI = (data) => {
	return new Promise((resolve, reject) => {
		const url = `/threads/${data.thread_id}/note`;

		axios({
			url,
			method: 'POST',
			data: data,
		})
			.then(res => {
				resolve(res.data.id || 0);
			})
			.catch(err => {
				reject(err);
			});
	});
};