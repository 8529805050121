import {
	Alert,
	AppBar,
	Box,
	Button,
	CircularProgress,
	Container,
	CssBaseline,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Paper,
	Slide,
	Snackbar,
	Stack,
	TextField,
	TextareaAutosize,
	Toolbar,
	Typography,
	colors,
	useScrollTrigger,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DocumentViewer from "../../components/documentViewer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	loadPresentation,
	presentation,
	presentationStatus,
	savePresentation,
	statuses,
} from "../../services/presentations/slice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { promiseContainer } from "../../components";
import styled from "styled-components";
import { 
	CheckCircleOutline as IconCheckCircleOutline,
	HighlightOff as IconHighlightOff,
	Save as IconSave, 
} from '@mui/icons-material';

type Status = 'draft' | 'viewed' | 'approved' | 'declined';

const Presentations = (props) => {
	const [submission, setSubmission] = useState({});
	const [html, setHtml] = useState("");
	const [comment, setComment] = useState("");
	const [declineModalOpen, setDeclineModalOpen] = useState(false);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

	const propsPresentation = useSelector(presentation);
	const propsPresentationStatus = useSelector(presentationStatus);
	const proposalStatus = propsPresentation.status?.toLowerCase();

	const { hash } = useParams();

	const save = (status: Status = proposalStatus || "draft"): Promise<unknown> => {
		const data = {
			proposal_data: submission,
			status,
			...(status === "declined" && { comment }),
		};

		return new Promise((resolve, reject) => {
			dispatch(savePresentation({ hash, data }))
				.unwrap()
				.then(resolve)
				.catch(reject);
		});
	};

	const handleSave = () => {
		save()
			.then(() => {
				promiseContainer(({ onConfirm, show }) => (
					<Snackbar
						open={show}
						autoHideDuration={2000}
						onClose={onConfirm}
						anchorOrigin={{ vertical: "top", horizontal: "right" }}
						TransitionComponent={(p) => (
							<Slide {...p} direction="left" />
						)}
					>
						<Alert
							onClose={onConfirm}
							severity="success"
							sx={{ width: "100%" }}
						>
							Changes have been saved
						</Alert>
					</Snackbar>
				));
			})
			.catch((err) => {
				promiseContainer(({ onConfirm, show }) => (
					<Dialog open={show} onClose={onConfirm}>
						<DialogTitle>Failed to save the changes</DialogTitle>
						<DialogContent>
							<DialogContentText>{err}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={onConfirm}>Okay</Button>
						</DialogActions>
					</Dialog>
				));
			});
	};

	const handleApprove = () => {

		promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
			open={show}
			onClose={onDismiss}
		>
			<DialogTitle>Approve Proposal</DialogTitle>
			<DialogContent>
				<DialogContentText>Do you want to approve the proposal?</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onDismiss} autoFocus>No</Button>
				<Button onClick={onConfirm} color="success" sx={{ fontWeight: 900 }}>Approve</Button>
			</DialogActions>
		</Dialog>
		)
			.then(() => {
				save("approved").then(() => {
					promiseContainer(({ onConfirm, show }) => (
						<Dialog open={show} onClose={onConfirm}>
							<DialogTitle>Proposal approved</DialogTitle>
							<DialogContent>
								<DialogContentText>
									Thank you for your approval.
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={onConfirm}>Okay</Button>
							</DialogActions>
						</Dialog>
					));
				});
			})
			.catch(() => { });

	};

	const handleDecline = () => {
		save("declined").then(() => {
			promiseContainer(({ onConfirm, show }) => (
				<Dialog open={show} onClose={onConfirm}>
					<DialogTitle>Proposal declined</DialogTitle>
					<DialogContent>
						<DialogContentText>😢</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={onConfirm}>Okay</Button>
					</DialogActions>
				</Dialog>
			)).finally(() => {
				setDeclineModalOpen(false);
			});
		});
	};

	useEffect(() => {
		if (hash) {
			dispatch(loadPresentation(hash))
				.unwrap()
				.then((res) => {
					setSubmission(res.proposal_data);
					setHtml(res.proposal_html);
				})
				.catch((err) => {
					promiseContainer(({ onDismiss, show }) => (
						<Dialog open={show} onClose={onDismiss}>
							<DialogTitle>
								Failed to load the presentation
							</DialogTitle>
							<DialogContent>
								<DialogContentText>{err}</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={onDismiss}>Okay</Button>
							</DialogActions>
						</Dialog>
					));
				});
		} else {
			//
		}
	}, []);

	return (
		<Box sx={{ backgroundColor: colors.grey[300], minHeight: "100%" }}>
			<Dialog
				open={declineModalOpen}
				onClose={() => setDeclineModalOpen(false)}
				fullWidth
				maxWidth="md"
			>
				<DialogTitle>
					Are you sure you want to decline this proposal?
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Typography sx={{ mb: 1 }}>
							Please write a comment to let us know the reason.
						</Typography>
						<TextAreaWrapper>
							<TextareaAutosize
								minRows={4}
								maxRows={10}
								value={comment}
								onChange={(e) => setComment(e.target.value)}
							></TextareaAutosize>
						</TextAreaWrapper>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						disabled={
							propsPresentationStatus === statuses.loading ||
							propsPresentationStatus === statuses.saving
						}
						onClick={handleDecline}
						color="error"
					>
						Decline
					</Button>
					<Button
						disabled={
							propsPresentationStatus === statuses.loading ||
							propsPresentationStatus === statuses.saving
						}
						onClick={() => setDeclineModalOpen(false)}
						sx={{ fontWeight: "900" }}
					>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			<CssBaseline />

			<ElevationScroll {...props}>
				<AppBar color="default">
					<Toolbar>
						<Stack
							flexDirection="row"
							justifyContent="space-between"
							alignItems="center"
							sx={{ width: "100%" }}
						>
							<Typography variant="h6" component="div">
								{propsPresentation.proposal_name}
							</Typography>

							{
								propsPresentationStatus !== statuses.loading &&
								<React.Fragment>

									{
										(
											proposalStatus === 'draft' ||
											proposalStatus === 'viewed'
										) &&
										<Box>
											<Button
												disabled={propsPresentationStatus === statuses.saving}
												onClick={handleSave}
											>
												<IconSave sx={{ mr: 1 }} />
												Save Changes
											</Button>
											<Button
												disabled={propsPresentationStatus === statuses.saving}
												color="success"
												onClick={handleApprove}
											>
												<IconCheckCircleOutline sx={{ mr: 1 }} />
												Approve
											</Button>
											<Button
												disabled={propsPresentationStatus === statuses.saving}
												color="error"
												onClick={() =>
													setDeclineModalOpen(true)
												}
											>
												<IconHighlightOff sx={{ mr: 1 }} />
												Decline
											</Button>
										</Box>
									}

									{
										proposalStatus === 'approved' &&
										<Typography sx={{ color: colors.green[600], fontWeight: '900' }}><IconCheckCircleOutline /> Approved</Typography>
									}

									{
										proposalStatus === 'declined' &&
										<Typography sx={{ color: colors.red[600], fontWeight: '900' }}>Declined</Typography>
									}

								</React.Fragment>
							}
						</Stack>
					</Toolbar>
				</AppBar>
			</ElevationScroll>

			<Toolbar />
			<Container maxWidth="md" sx={{ py: 4 }}>
				{propsPresentationStatus === statuses.loading && (
					<Stack alignItems="center" justifyContent="center">
						<CircularProgress size={24} color="inherit" />
					</Stack>
				)}

				{!!propsPresentation.proposal_html &&
					propsPresentationStatus !== statuses.loading && (
					<Paper elevation={8} square>
						<DocumentViewer
							htmlTemplate={html}
							data={submission}
							editableFields={
								(proposalStatus === 'approved' || proposalStatus === 'declined') ? false : []
							}
							onChange={setSubmission}
						/>
					</Paper>
				)}
			</Container>
		</Box>
	);
};

interface Props {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window?: () => Window;
	children: React.ReactElement;
}

const ElevationScroll = (props: Props) => {
	const { children, window } = props;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? window() : undefined,
	});

	return React.cloneElement(children, {
		elevation: trigger ? 4 : 0,
	});
};

export default Presentations;

const TextAreaWrapper = styled.div`
	textarea {
		width: 100%;
		outline: none;
		border: solid 1px #aaa;
		border-radius: 0.25rem;
		padding: 0.5rem;
	}
`;
