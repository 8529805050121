import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";

import {

	Box,
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Fab,
	Tab,
	Tabs,
	Chip,
	Stack,
	Typography,
	Menu,
	MenuItem,
	AppBar,
	Toolbar,
	IconButton,
} from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';

import { useNavigate, useParams } from "react-router-dom";

import {
	loadThread,
	sendMessage,
	saveNote,
	threadStatus,
	statuses,
} from '../../services/threads/slice';

import {
	ArrowBack as IconArrowBack,
	Send as IconSend,
	AdminPanelSettings as IconAdminPanelSettings,
	Logout as IconLogout,
} from '@mui/icons-material';



import Moment from 'moment';


import ThreadsSidebar from './threadsSidebar';

import { promiseContainer } from '../../components';
import { authResult, authResults, authObject, logout } from '../../services/auth/slice';
import { ThunkDispatch } from '@reduxjs/toolkit';


import EditorJS from '@editorjs/editorjs';
// import { Header as EditorHeader } from '@editorjs/header';
// import { List as EditorList } from '@editorjs/list'; 





const ThreadsTicket = props => {

	const propsAuthResult = useSelector(authResult);
	const propsAuthObject = useSelector(authObject);
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState('threads');

	const { threadId } = useParams();


	const frViewRef = useRef(null);
	const replyRef = useRef(null)
	const noteRef = useRef(null)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

	const [avatarMenuAnchorEl, setAvatarMenuAnchorEl] = React.useState(null);
	const avatarMenuOpen = Boolean(avatarMenuAnchorEl);

	useEffect(() => {
		if (propsAuthResult === authResults.failed) {
			navigate('/threads');
		}
	}, [propsAuthResult]);


	const [threadData, setThreadData] = useState({});
	const status = useSelector(threadStatus);

	const [isReplying, setIsReplying] = useState(false);
	const [replyMessage, setReplyMessage] = useState('');

	const [isAddingNote, setIsAddingNote] = useState(false);
	const [noteMessage, setNoteMessage] = useState('');

	const onSelectAction = (i) => {
		console.log(i)
	}

	const replyEditor = new EditorJS({
		holder: 'reply-editor',
		placeholder: 'Enter your reply to the customer...',
		onChange: (api, event) => {
			console.log('change')
			console.log(api)
			console.log(event)
		},
		// tools: {
		// 	header: {
		// 		class: EditorHeader, 
		// 		inlineToolbar: ['link'] 
		// 	}, 
		// }
	});


	const noteEditor = new EditorJS({
		holder: 'note-editor',
		placeholder: 'Enter a private note for this ticket',
		onChange: (api, event) => {
			console.log('change')
			console.log(api)
			console.log(event)
		},
		// tools: {
		// 	header: {
		// 		class: EditorHeader, 
		// 		inlineToolbar: ['link'] 
		// 	}, 
		// }
	});



	const onSendReply = () => {

		replyEditor.readOnly.toggle();
		replyEditor.save().then((outputData) => {
			console.log('Article data: ', outputData)
		}).catch((error) => {
			console.log('Saving failed: ', error)
		});
		/*
		dispatch(sendMessage({
			thread_id: threadId,
			message: replyMessage,
		}))
			.then(res => {
				navigate(`/threads/${threadId}`);
			})
			.catch(err => {
				promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
					open={show}
					onClose={onDismiss}
				>
					<DialogTitle>Failed to create a new thread</DialogTitle>
					<DialogContent>
						<DialogContentText>{err}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={onDismiss}>Cancel</Button>
						<Button onClick={onConfirm} autoFocus>Retry</Button>
					</DialogActions>
				</Dialog>
				)
					.then(() => { })
					.catch(() => { });
			});
		/** */
	};

	const onSaveNote = () => {
		dispatch(saveNote({
			thread_id: threadId,
			message: noteMessage,
		}))
			.then(res => {
				navigate(`/threads/${threadId}`);
			})
			.catch(err => {
				promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
					open={show}
					onClose={onDismiss}
				>
					<DialogTitle>Failed to create a new thread</DialogTitle>
					<DialogContent>
						<DialogContentText>{err}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={onDismiss}>Cancel</Button>
						<Button onClick={onConfirm} autoFocus>Retry</Button>
					</DialogActions>
				</Dialog>
				)
					.then(() => { })
					.catch(() => { });
			});
	};

	useEffect(() => {
		const load = () => {
			dispatch(loadThread(threadId))
				.unwrap()
				.then(data => {
					console.log('threaddata');
					console.log(data.messages)
					setThreadData(data);
				})
				.catch(err => {
					promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
						open={show}
						onClose={onDismiss}
					>
						<DialogTitle>Failed to load the proposal template list</DialogTitle>
						<DialogContent>
							<DialogContentText>{err}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={onDismiss}>Cancel</Button>
							<Button onClick={onConfirm} autoFocus>Retry</Button>
						</DialogActions>
					</Dialog>
					)
						.then(() => {
							load();
						})
						.catch(() => { });
				});
		};
		load();
	}, []);

	const handleAvatarClick = (event) => {
		setAvatarMenuAnchorEl(event.currentTarget);
	};

	const handleCloseAvatarMenu = () => {
		setAvatarMenuAnchorEl(null);
	};

	const handleLogout = () => {
		handleCloseAvatarMenu();

		dispatch(logout())
			.unwrap()
			.finally(() => {
				navigate('/');
			});
	};

	const onBack = () => {
		navigate('/threads');
	}

	const scrollToReply = () => {
		// replyRef.scrollIntoView();
	}

	const scrollToNote = () => {
		// noteRef.scrollIntoView();
	}


	const createMarkup = (html: string) => ({ __html: html });

	return (
		<>

			<Box sx={{ flexGrow: 0, flexShrink: 0, zIndex: 1200 }}>
				<AppBar sx={{ position: 'static' }} elevation={0}>
					<Toolbar>
						<Stack sx={{ flexGrow: 1 }} direction="row" alignItems="center">

							<IconButton
								edge="start"
								color="inherit"
								sx={{ flexGrow: 0 }}
								onClick={onBack}
							>
								<IconArrowBack />
							</IconButton>

							<Stack flexDirection="row">
								<Typography
									nowrap
									sx={{
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										maxWidth: '360px',
									}}
								>Ticket Details</Typography>
							</Stack>
						</Stack>
					</Toolbar>
				</AppBar>
			</Box>

			<Container>

				<Stack flexDirection="row" alignItems="center" justifyContent="space-between">

					<Box>

						<Menu
							id="basic-menu"
							anchorEl={avatarMenuAnchorEl}
							open={avatarMenuOpen}
							onClose={handleCloseAvatarMenu}
							MenuListProps={{
								'aria-labelledby': 'basic-button',
							}}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							<MenuItem disabled divider>
								<Stack>
									<Typography sx={{ fontWeight: '700' }}>{propsAuthObject?.auth_name}</Typography>
									<Typography>{propsAuthObject?.auth_email}</Typography>
								</Stack>
							</MenuItem>

							<MenuItem onClick={handleLogout}>
								<IconLogout sx={{ mr: 1 }} />
								Logout
							</MenuItem>
						</Menu>
					</Box>

				</Stack>

				<Box sx={{ pb: 16 }}>
					{
						threadData &&
						<Box>
							<Typography my={4} variant="h3" sx={{ fontWeight: '700' }}>{threadData.thread_subject || 'No subject'}</Typography>
							<Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
								<Box p={3} sx={{ borderRadius: '1rem', border: '1px solid #e3e4e1' }}>
									<Typography>Created:</Typography>
									<Typography variant="h6">{Moment(threadData.date_created).format('ddd D MMM, YYYY h:mm A')}</Typography>
								</Box>
								<Box p={3} sx={{ borderRadius: '1rem', border: '1px solid #e3e4e1' }}>
									<Typography>Last Updated:</Typography>
									<Typography variant="h6">{Moment(threadData.date_updated).format('ddd D MMM, YYYY h:mm A')}</Typography>
								</Box>
								<Box p={3} sx={{ borderRadius: '1rem', border: '1px solid #e3e4e1' }}>
									<Typography>Status:</Typography>
									{(() => {
										let color;
										let variant;
										switch (threadData.thread_status?.toLowerCase()) {
											case 'closed':
												color = 'success';
												variant = 'filled';
												break;
											case 'late':
												color = 'error';
												variant = 'filled';
												break;
											case 'open':
												color = 'warning';
												variant = 'filled';
												break;
											default:
												color = 'default';
												variant = 'outlined';
												break;
										}

										return (
											<Chip label={threadData.thread_status} size="small" sx={{ mr: 1, textTransform: 'uppercase' }}
												variant={variant}
												color={color}
											/>
										);
									})()}
								</Box>
							</Stack>
							<Tabs
								variant="scrollable"
								className="position-sticky bg-white"
								sx={{ position: 'sticky', 'top': 0 }}
								scrollButtons
								allowScrollButtonsMobile>
								<Tab label="Thread Details" value="details" onClick={() => {
									setIsReplying(false);
									setIsAddingNote(false);
								}} />
								<Tab label="Reply" value="reply" onClick={() => {
									scrollToReply();
									setIsReplying(!isReplying);
									setIsAddingNote(false);
								}} />
								<Tab label="Add Note" value="note" onClick={() => {
									scrollToNote();
									setIsReplying(false);
									setIsAddingNote(!isAddingNote);
								}} />
							</Tabs>
							{
								(threadData?.messages || []).map((message, key) => (
									<React.Fragment key={`list_item_${key}`}>
										<Box mb={3} p={3} sx={{ borderRadius: '1rem', border: '1px solid #e3e4e1' }}>
											<Typography variant="h6">{Moment(message.date_created).fromNow()}</Typography>
											<Box dangerouslySetInnerHTML={createMarkup(message.message_content)}></Box>
										</Box>
									</React.Fragment>
								))
							}
							{
								isReplying &&
								<Box ref={replyRef} my={3} p={3} sx={{ borderRadius: '1rem', border: '1px solid #e3e4e1' }}>
									<Typography>Reply to this thread:</Typography>
									<div>
										<div id="reply-editor"></div>
									</div>


									<Fab variant="extended" size="medium" color="primary"
										onClick={onSendReply}>
										<IconSend sx={{ mr: 1 }} />
										Send Reply
									</Fab>
								</Box>
							}
							{
								isAddingNote &&
								<Box ref={noteRef} my={3} p={3} sx={{ borderRadius: '1rem', border: '1px solid #e3e4e1' }}>
									<Typography>Add a private note this thread:</Typography>
									<div>
										<div id="note-editor"></div>
									</div>

									<Fab variant="extended" size="medium" color="secondary"
										onClick={onSaveNote}>
										<IconAdminPanelSettings sx={{ mr: 1 }} />
										Save Note
									</Fab>
								</Box>
							}
						</Box>
					}
				</Box>
			</Container>

		</>
	);
};

export default ThreadsTicket;