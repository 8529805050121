import React, { useState, useEffect, useMemo, useDeferredValue } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { 
	Box,
	Button,
	Container,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Fab,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	ListItemButton,
	IconButton,
	Menu,
	MenuItem,
	Stack,
	Typography,
	Chip,
} from '@mui/material';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

import { 
	Add as IconAdd, 
	MoreHoriz as IconMoreHoriz,
	Edit as IconEdit,
	Visibility as IconVisibility,
	Delete as IconDelete,
} from '@mui/icons-material';

import { Link, useNavigate } from "react-router-dom";

import Moment from 'moment';
import PropTypes from 'prop-types';

import { 
	loadProposalList,
	deleteProposal,
	proposalList,
	proposalStatus,
	statuses,
} from '../../services/proposals/slice';

import { promiseContainer } from '../../components';
import NewProposal from '../templates/newProposal';
import { authResult, authResults, authObject } from '../../services/auth/slice';

const ProposalList = props => {

	const [ menuAnchorEl, setMenuAnchorEl ] = useState(null);
	const [ menuAnchorData, setMenuAnchorData ] = useState(null);

	const [ menuId, setMenuId ] = useState(null);
	
	const propsAuthResult = useSelector(authResult);

	const list = useSelector(proposalList) || [];
	const status = useSelector(proposalStatus);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [keyword, setKeyword] = useState('');
	const deferredKeyword = useDeferredValue(keyword);

	const filteredList = useMemo(() => {
		const reg = new RegExp(_.escapeRegExp(keyword).replace(/ /g, '.*'), 'i');

		return (list || []).filter(item => {
			return (
				reg.test(item?.proposal_name) ||
				reg.test(item?.school_name)
			);
		});
	}, [deferredKeyword, list]);

	useEffect(() => {

		const load = () => {
			dispatch(loadProposalList())
				.unwrap()
				.catch(err => {
					promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
						open={show}
						onClose={onDismiss}
					>
						<DialogTitle>Failed to load the proposal list</DialogTitle>
						<DialogContent>
							<DialogContentText>{err}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={onDismiss}>Cancel</Button>
							<Button onClick={onConfirm} autoFocus>Retry</Button>
						</DialogActions>
					</Dialog>
					)
						.then(() => {
							load();
						})
						.catch(() => { });
				});
		};

		load();
	}, []);

	useEffect(() => {
		if (propsAuthResult === authResults.failed) navigate('/');
	}, [ propsAuthResult ]);

	const onMoreButtonClick = (e, item) => {
		setMenuAnchorEl(e.currentTarget);
		setMenuAnchorData(item);
		setMenuId(item.proposal_id);
	};

	const closeMoreMenu = () => {
		setMenuAnchorEl(null);
		setMenuAnchorData(null);
		setMenuId(null);
	};

	const createNew = () => {
		promiseContainer(({ onConfirm, onDismiss, show }) => (
			<NewProposal {...{ onConfirm, onDismiss, show }} />
		))
			.then(res => {
				navigate(`/proposals/${res}${window.location.search || ''}`);
			})
			.catch(() => { });
	};

	const onDelete = () => {
		const deleteOne = () => {
			dispatch(deleteProposal({ proposalId: menuAnchorData.proposal_id }))
				.unwrap()
				.catch(err => {
					promiseContainer(({ onDismiss, show }) => <Dialog
						open={show}
						onClose={onDismiss}
					>
						<DialogTitle>Failed to delete</DialogTitle>
						<DialogContent>
							<DialogContentText>{err}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={onDismiss}>OK</Button>
						</DialogActions>
					</Dialog>
					).catch(() => { });
				});
		};

		promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
			open={show}
			onClose={onDismiss}
		>
			<DialogTitle>Delete Proposal</DialogTitle>
			<DialogContent>
				<DialogContentText>Are you sure you want to delete &ldquo;{menuAnchorData?.proposal_name}&rdquo;?</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onDismiss} autoFocus>No</Button>
				<Button onClick={onConfirm} color="error">Delete</Button>
			</DialogActions>
		</Dialog>
		)
			.then(() => {
				deleteOne();
			})
			.catch(() => { })
			.finally(() => {
				closeMoreMenu();
			});
	};

	return (
		<Container>

			<Stack flexDirection="row" alignItems="center" justifyContent="space-between">

				<Box sx={{ my: 5 }}>
					<Typography
						variant='h5'
						color="default"
						sx={{
							fontWeight: 700,
							display: {
								sm: 'inline',
								xs: 'none',
							}
						}}
					>Inbox Design Proposals</Typography>
				</Box>

				<Box>

					<Fab
						variant="extended"
						color="primary"
						size="medium"
						onClick={createNew}
						disabled={status === statuses.saving}
					><IconAdd sx={{ mr: 1 }} />New Proposal</Fab>

				</Box>

			</Stack>

			<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>

				<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
					<Paper
						variant="outlined"
						component="form"
						sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 200 }}
					>
						<InputBase
							sx={{ ml: 1, flex: 1 }}
							placeholder="Search Porposals"
							inputProps={{ 'aria-label': 'search porposals' }}
							value={keyword}
							onChange={e => setKeyword(e.target.value)}
						/>
						<IconButton type="button" sx={{ p: '10px' }} aria-label="search">
							<SearchIcon />
						</IconButton>
					</Paper>
				</Stack>
			</Stack>

			<Stack alignItems="center" justifyContent="center">
				{
					status === 'LOADING' &&
					<CircularProgress size={32} sx={{ mb: 2 }} />
				}

				{
					status === 'LOADED' && !list?.length &&
					<Box sx={{ borderTopStyle: 'solid', borderTopWidth: 1, borderColor: 'lightgray', width: '100%', py: 6 }}>
						<Typography variant="h6" color="gray" sx={{ textAlign: 'center' }}>You have not created any proposas yet. Use the &ldquo;+ New&rdquo; button to add your first proposal.</Typography>
					</Box>
				}
			</Stack>

			<List sx={{ pb: 16 }}>

				<Menu
					anchorEl={ menuAnchorEl }
					open={ Boolean(menuAnchorEl) }
					onClose={ closeMoreMenu }
				>
					{
						menuAnchorData?.status?.toLowerCase() === 'draft' ?
							<>
								<MenuItem component={Link} to={`/proposals/${menuId}${window.location.search || ''}`} divider>
									<ListItemIcon><IconEdit fontSize="small" /></ListItemIcon>
									<ListItemText>Edit</ListItemText>
								</MenuItem>

								<MenuItem sx={{ color: 'error.dark' }} onClick={onDelete}>
									<ListItemIcon><IconDelete fontSize="small" color="error" /></ListItemIcon>
									<ListItemText>Delete</ListItemText>
								</MenuItem>
							</>
							:
							<MenuItem component={Link} to={`/proposals/${menuId}${window.location.search || ''}`}>
								<ListItemIcon><IconVisibility fontSize="small" /></ListItemIcon>
								<ListItemText>View</ListItemText>
							</MenuItem>
					}

				</Menu>

				<Divider component="li" />

				{
					filteredList.map((item, key) => (
						<React.Fragment key={ `calculation_list_item_${key}` }>

							<ListItem
								secondaryAction={
									<IconButton aria-label="more options" onClick={ e => onMoreButtonClick(e, item) }>
										<IconMoreHoriz />
									</IconButton>
								}
								divider
								disablePadding
							>
								<ListItemButton component={Link} 
									to={`/proposals/${item.proposal_id}${window.location.search || ''}`}
								>
									<Stack direction="row" alignItems="center" justifyContent="space-between" 
										sx={{ 
											width: '100%',
											flexDirection: {
												md: 'row',
												sm: 'column',
												xs: 'column',
											},
											alignItems: {
												md: 'center',
												sm: 'start',
												xs: 'start',
											},
										}}
									>
										<ListItemText
											sx={{ flexGrow: 1, mr: 2 }}
											primary={item?.proposal_name || ''}
											secondary={item?.school_name || ''}
										/>
										
										<Stack alignItems="center"
											sx={{
												flexDirection: {
													md: 'row',
													sm: 'row-reverse',
													xs: 'row-reverse',
												}
											}}
										>

											<ListItemText
												sx={{ whiteSpace: 'nowrap', mr: 1 }}
												secondary={Moment(item.date_created).format('ddd D MMM, YYYY h:mm A')}
											/>

											{(() => {
												let color;
												let variant;
												switch (item.status?.toLowerCase()) {
													case 'approved':
														color = 'success';
														variant = 'filled';
														break;
													case 'declined':
														color = 'error';
														variant = 'filled';
														break;
													case 'viewed':
														color = 'warning';
														variant = 'filled';
														break;
													default: '';
														color = 'default';
														variant = 'outlined';
														break;
												}

												return (
													<Chip label={item.status} size="small" sx={{ mr: 1, textTransform: 'uppercase' }}
														variant={ variant }
														color={ color }
													/>
												);
											})()}

										</Stack>

									</Stack>
								</ListItemButton>
							</ListItem>

						</React.Fragment>
					))
				}
			</List>
		</Container>
	);
};

export default ProposalList;

ProposalList.propTypes = {
	firmId: PropTypes.string,
	clientId: PropTypes.string,
	calcId: PropTypes.string,
};