import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { 
	fetchList, 
} from "./api";

export const statuses = {
	idle: 'IDLE',
	loading: 'LOADING',
	loaded: 'LOADED',
	saving: 'SAVING',
	deleting: 'DELETING',
	saved: 'SAVED',
	error: 'ERROR',
};

const initialState = {
	list: [],
	status: statuses.idle,
};

export const loadSchoolList = createAsyncThunk(
	'school/fetchList',
	async (_, { rejectWithValue }) => {
		try{
			const response = await fetchList();
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const schoolSlice = createSlice({
	name: 'schools',
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(loadSchoolList.pending, state => {
				state.status = statuses.loading;
			}).addCase(loadSchoolList.fulfilled, (state, action) => {
				state.status = statuses.loaded;
				state.list = action.payload;
			}).addCase(loadSchoolList.rejected, state => {
				state.status = statuses.error;
			});
	}
});

export const schoolList = (state) => state.schools?.list;
export const schoolStatus = (state) => state.schools?.status;

export default schoolSlice.reducer;