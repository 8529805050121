import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { 
	fetchList, 
	create,
	featchTemplate,
	save,
} from "./api";

export const statuses = {
	idle: 'IDLE',
	loading: 'LOADING',
	loaded: 'LOADED',
	saving: 'SAVING',
	deleting: 'DELETING',
	saved: 'SAVED',
	error: 'ERROR',
};

const initialState = {
	template: {},
	list: [],
	status: statuses.idle,
};

export const loadTemplate = createAsyncThunk(
	'template/fetch',
	/**
	 * @param {*} templateId 
	 */
	async (templateId, { rejectWithValue }) => {
		try{
			const response = await featchTemplate(templateId);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const loadTemplateList = createAsyncThunk(
	'template/fetchList',
	async (_, { rejectWithValue }) => {
		try{
			const response = await fetchList();
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const createTemplate = createAsyncThunk(
	'template/create',
	/**
	 * 
	 * @param {object} data 
	 */
	async (data, { rejectWithValue }) => {
		try{
			const response = await create(data);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const saveTemplate = createAsyncThunk(
	'template/save',
	/**
	 * 
	 * @param {object} data 
	 */
	async ({ id, data }, { rejectWithValue }) => {
		try{
			const response = await save(id, data);
			return data;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);


export const templateSlice = createSlice({
	name: 'templates',
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(loadTemplate.pending, state => {
				state.status = statuses.loading;
			})
			.addCase(loadTemplate.fulfilled, (state, action) => {
				state.status = statuses.loaded;
				state.template = action.payload;
			})
			.addCase(loadTemplate.rejected, state => {
				state.status = statuses.error;
			})

			.addCase(saveTemplate.pending, state => {
				state.status = statuses.saving;
			})
			.addCase(saveTemplate.fulfilled, (state, action) => {
				state.status = statuses.saved;
				state.template = {
					...state.template,
					...action.payload,
				}
			})
			.addCase(saveTemplate.rejected, state => {
				state.status = statuses.error;
			})

			.addCase(loadTemplateList.pending, state => {
				state.status = statuses.loading;
			})
			.addCase(loadTemplateList.fulfilled, (state, action) => {
				state.status = statuses.loaded;
				state.list = action.payload;
			})
			.addCase(loadTemplateList.rejected, state => {
				state.status = statuses.error;
			});
	}
});

export const templateData = (state) => state.templates?.template;
export const templateList = (state) => state.templates?.list;
export const templateStatus = (state) => state.templates?.status;

export default templateSlice.reducer;