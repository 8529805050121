import axios from 'axios';

export const featchTemplate = (templateId) => {
	return new Promise((resolve, reject) => {
		const url = `/templates/${templateId}`;

		axios({
			url,
			method: 'get',
		})
			.then(res => {
				resolve(res.data || {});
			})
			.catch(err => {
				reject(err);
			});
	});
};
export const fetchList = () => {
	return new Promise((resolve, reject) => {
		const url = `/templates`;

		axios({
			url,
			method: 'get',
		})
			.then(res => {
				resolve(res.data || []);
			})
			.catch(err => {
				reject(err);
			});
	});
};

export const create = (data) => {
	return new Promise((resolve, reject) => {
		const url = `/templates`;

		axios({
			url,
			method: 'POST',
			data: data,
		})
			.then(res => {
				resolve(res.data?.id || 0);
			})
			.catch(err => {
				reject(err);
			});
	});
};

export const save = (templateId, data) => {
	return new Promise((resolve, reject) => {
		const url = `/templates/${templateId}`;

		axios({
			url,
			method: 'PUT',
			data: data,
		})
			.then(res => {
				resolve(res.data?.id || 0);
			})
			.catch(err => {
				reject(err);
			});
	});
};