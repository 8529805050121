import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { 
	expireToken,
	login, retrieve,
} from "./api";

import Cookies from 'js-cookie';
import axios from "axios";

export const statuses = {
	idle: 'IDLE',
	loading: 'LOADING',
	loaded: 'LOADED',
	saving: 'SAVING',
	deleting: 'DELETING',
	saved: 'SAVED',
	error: 'ERROR',
};

export const authResults = {
	unauthed: 'UNAUTHED',
	authed: 'AUTHED',	
	failed: 'FAILED',
};

const initialState = {
	token: null,
	authResult: authResults.unauthed,
	authObject: null,
	status: statuses.idle,
};

export const validateToken = createAsyncThunk(
	'auth/retrieve',
	async (data, { rejectWithValue }) => {
		try{
			const response = await retrieve(data);
			return response;
		}catch(err){
			console.log('retrieve failed', err);
			return rejectWithValue(err.message);
		}
	}
);

export const logout = createAsyncThunk(
	'auth/logout',
	async (_, { rejectWithValue }) => {
		try{
			const response = await expireToken();
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const saveLogin = createAsyncThunk(
	'auth/token',
	async (data, { rejectWithValue }) => {
		try{
			const response = await login(data);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const loadToken = createAsyncThunk(
	'auth/loadToken',
	async (_, { rejectWithValue }) => {
			const token = Cookies.get('token');
		if (token) {
			return token;
		} else {
			return rejectWithValue('No token in the cookie');
		}
	}
);

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuthToken: (state, action) => {
			const token = action.payload;
			Cookies.set('token', token, { expires: 7 });
			state.token = token;
		},
		getAuthToken: (state) => {
			const token = Cookies.get('token');
			state.token = token;
		},
		// 	loadProposalList: (state) => {
		// 		state.list = [ 
		// 			{
		// 				id: 'calc_1',
		// 				name: 'Calc 1',
		// 				data: { val_1: 16 },
		// 				created_at: new Date().toString(),
		// 				modified_at: new Date().toString(),
		// 			},
		// 			{
		// 				id: 'calc_2',
		// 				name: 'Calc 2',
		// 				data: { val_1: 16 },
		// 				created_at: new Date().toString(),
		// 				modified_at: new Date().toString(),
		// 			},
		// 			{
		// 				id: 'calc_3',
		// 				name: 'Calc 3',
		// 				data: { val_1: 16 },
		// 				created_at: new Date().toString(),
		// 				modified_at: new Date().toString(),
		// 			},
		// 		];
		// 	}
	},
	extraReducers: (builder) => {
		builder
			.addCase(loadToken.fulfilled, (state, action) => {
				const token = action.payload;
				state.token = token;
			})
			.addCase(loadToken.rejected, state => {
				state.status = statuses.error;
			})
			.addCase(saveLogin.pending, state => {
				state.status = statuses.loading;
			})
			.addCase(saveLogin.fulfilled, (state, action) => {
				const { auth_token = null, ...rest } = action.payload;

				axios.defaults.headers.common['Auth-Token'] = auth_token;

				state.authResult = authResults.authed;
				state.status = statuses.loaded;
				state.authObject = rest;
				state.token = auth_token;

				Cookies.set('token', auth_token, { expires: 7 });
			})
			.addCase(saveLogin.rejected, state => {
				state.status = statuses.error;
			})
			.addCase(validateToken.pending, state => {
				state.authResult = authResults.unauthed;
				state.status = statuses.loading;
			})
			.addCase(validateToken.fulfilled, (state, action) => {
				const { auth_token = null, ...rest } = action.payload;

				state.authResult = authResults.authed;
				state.token = auth_token;
				state.authObject = rest;
				state.status = statuses.loaded;
			})
			.addCase(validateToken.rejected, state => {
				state.authResult = authResults.failed;
				state.status = statuses.error;
			})
			.addCase(logout.pending, state => {
				state.status = statuses.loading;
			})
			.addCase(logout.fulfilled, state => {
				Cookies.remove('token');
				state.status = statuses.loaded;
				state.token = null;
				state.authObject = null;
				state.authResult = authResults.unauthed;
				delete axios.defaults.headers.common['Auth-Token'];
			})
			.addCase(logout.rejected, state => {
				Cookies.remove('token');
				state.status = statuses.loaded;
				state.token = null;
				state.authObject = null;
				state.authResult = authResults.unauthed;
				delete axios.defaults.headers.common['Auth-Token'];
			});
	}
});

export const { getAuthToken } = authSlice.actions;

export const token = state => state.auth.token;
export const authResult = state => state.auth.authResult;
export const authStatus = state => state.auth.status;
export const authObject = state => state.auth.authObject;

export default authSlice.reducer;