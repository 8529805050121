import React from 'react';
import { useRouteError } from 'react-router-dom';
import {
	Container,
} from 'reactstrap';
import { ReactComponent as Logo } from '../assets/logo.svg';

const RouterError: React.FC = () => {
	const error: unknown = useRouteError();

	return (
		<Container className="h-100 d-flex align-items-center justify-content-center">
			<div className="d-flex flex-column align-items-center">
				<Logo className="ibdn-logo mb-4" width="240" />
				<h5 className="text-center text-danger fw-light">
					{
						error?.status ?
							`${error?.data} (${error?.status})`
							: `Oops! The page was never built, check out our website.`
					}
					
				</h5>
				<a href="https://www.inboxdesign.co.nz/" className="text-center text-primary fw-light mt-4 p-3" target="_blank" rel="noreferrer">www.inboxdesign.co.nz</a>
			</div>
		</Container>
	);
};

export default RouterError;