"use client";

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, NativeSelect, Paper, Select, Stack, TextField, Typography, colors } from '@mui/material';
import { loadTemplateList, templateList, templateStatus } from '../../services/templates/slice';
import { loadSchoolList, schoolList, schoolStatus } from '../../services/schools/slice';
import { createProposal, sendProposal, newLink, removeLink, proposal, proposalStatus, statuses } from '../../services/proposals/slice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import {
	Close as IconClose,
} from '@mui/icons-material';
import { promiseContainer } from '../../components';

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export interface Links {
	show: boolean;
	onDismiss: (...args: unknown[]) => Promise<unknown>;
	onConfirm: (...args: unknown[]) => Promise<unknown>;
}

const Links: React.FC<Links> = (props): JSX.Element => {

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	
	const [defaultEmailMessage, setDefaultEmailMessage] = useState('Hello {{name}}, Please click the below link to view your proposal');
	const [emailMessage, setEmailMessage] = useState(defaultEmailMessage);

	const { show, onConfirm } = props;

	const propsProposal = useSelector(proposal);
	const propsProposalStatus = useSelector(proposalStatus);


	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

	const onCreateNewLink = () => {

		const data = {
			proposalId: propsProposal.id,
			data: {
				link_name: name,
				link_email: email,
			},
		};
		dispatch(newLink(data))
			.unwrap()
			.then(() => {
				setName('');
				setEmail('');
			})
			.catch(err => {
				promiseContainer(({ onConfirm, show }) => (
					<Dialog open={show} onClose={onConfirm}>
						<DialogTitle>Error</DialogTitle>
						<DialogContent>
							<DialogContentText>Failed to create the link.</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={onConfirm} color="error">Okay</Button>
						</DialogActions>
					</Dialog>
				));
			});
	};

	const onRemoveLink = (link_id) => {
		dispatch(removeLink(propsProposal.id, link_id))
			.unwrap()
			.then(() => {
				setName('');
				setEmail('');
			})
			.catch(err => {
				promiseContainer(({ onConfirm, show }) => (
					<Dialog open={show} onClose={onConfirm}>
						<DialogTitle>Error</DialogTitle>
						<DialogContent>
							<DialogContentText>Failed to create the link.</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={onConfirm} color="error">Okay</Button>
						</DialogActions>
					</Dialog>
				));
			});
	};

	const onSendProposal = () => {
		
		const payload = { 
			proposal_id: propsProposal.id,
			email_message: emailMessage,
		};

		dispatch(sendProposal(payload))
			.unwrap()
			.then(res => {
				onConfirm(res.id);
			})
			.catch(err => {

				promiseContainer(({ onConfirm, show }) => (
					<Dialog open={show} onClose={onConfirm}>
						<DialogTitle>Error</DialogTitle>
						<DialogContent>
							<DialogContentText>Failed to send your proposal. Please try it again.</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={onConfirm} color="error">Okay</Button>
						</DialogActions>
					</Dialog>
				))
					.catch(() => { });

			});
	};

	return (
		<Dialog open={show} onClose={onConfirm} fullWidth maxWidth="md">
			<DialogTitle>
				Links
				<IconButton
					aria-label="close"
					onClick={onConfirm}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<IconClose />
				</IconButton>
			</DialogTitle>

			<DialogContent>
				<Box sx={{ py: 3 }}>
					<Grid container spacing={2} mb={3}>
						<Grid item md={6} sm={12} xs={12}>
							<TextField
								label="Name"
								size="small"
								sx={{ width: "100%" }}
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</Grid>

						<Grid item md={4} sm={12} xs={12}>
							<TextField
								label="Email"
								size="small"
								sx={{ width: "100%" }}
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</Grid>

						<Grid item md={2} sm={12} xs={12}>
							<Button
								variant="contained"
								color="success"
								sx={{ width: "100%" }}
								disabled={ propsProposalStatus === statuses.saving || !name || !emailRegex.test(email) }
								onClick={onCreateNewLink}
							>
								Add
							</Button>
						</Grid>
					</Grid>
					{
						!!(propsProposal.links || []).length &&
						<Paper variant="outlined" sx={{ mb: 3 }}>
							<List>
								{(propsProposal.links || []).map((link, key) => (
									<React.Fragment key={`link_item_${key}`}>
										<ListItem>
											<Stack flexDirection="row" alignItems="center" justifyContent="space-between">
												<Stack flexDirection="column" alignItems="between" sx={{ overflow: 'hidden' }}>
													<Stack flexDirection="row" alignItems="center">
														<Typography sx={{ fontWeight: '700', mr: 2 }}>
															{link.link_name}
														</Typography>
														<Typography variant="subtitle1">
															{link.link_email}
														</Typography>
													</Stack>
													<Typography color="gray">
														{
															(() => {
																const url = `${window.location.origin}/presentations/${link.link_hash}`;
																return <a href={url} target="_blank" rel="noreferrer">{url}</a>;
															})()
														}
													</Typography>
												</Stack>
												<Fab
													size="small"  
													color="error"  
													onClick={(e) => onRemoveLink(link.id)}>x</Fab>
											</Stack>
										</ListItem>
										{
											(key < (propsProposal.links || []).length - 1) && <Divider />
										}
									</React.Fragment>
								))}
								{
									propsProposal.links.length &&
										<ListItem>
											<Stack 
												direction="column"
												justifyContent="space-around"
												alignItems="stretch"
												spacing={1}>
												<Typography  mb={3} sx={{ fontWeight: '700', mr: 2 }}>
													Send Proposal
												</Typography>
												<TextField
													required
													id="outlined-required"
													label="Email Message"
													multiline
													rows={8}
													sx={{
														'& .MuiTextField-root': { m: 1, width: '100%' },
													}}
													defaultValue={defaultEmailMessage}
													onChange={(e) => setEmailMessage(e.target.value)}
												/>
												<Button
													variant="contained"
													color="success"
													sx={{ width: "100%" }}
													disabled={ propsProposalStatus === statuses.saving || !emailMessage }
													onClick={onSendProposal}
												>
													Send
												</Button>
											</Stack>
										</ListItem>

								}
							</List>
						</Paper>
					}
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default Links;