import React, { useState, useEffect } from 'react';
import {

	BrowserRouter,
	Routes,
	Route,
	Link,

	RouterProvider,
	createRoutesFromElements,
	createBrowserRouter,
} from 'react-router-dom';
import { RouterError } from './components';
import axios from 'axios';
import Moment from 'moment';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Theme } from './style/theme';

import WelcomeScreen from './features/auth/welcome';
import ProposalList from './features/proposals/proposalList';
import { Proposals } from './features/proposals/proposals';
import TemplateList from './features/templates/templateList';
import TemplateEdit from './features/templates/templateEdit';
import ThreadsList from './features/threads/threadsList';
import ThreadsTicket from './features/threads/threadsTicket';

import Sidebar from './components/sidebarWrapper';

import preval from 'preval.macro';

import packageJSON from '../package.json';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { validateToken } from './services/auth/slice';
import Presentations from './features/presentations/presentations';
import Home from './features/home';



import {
	Button,
	Stack,
	Typography,
} from '@mui/material';
import SidebarWrapper from './components/sidebarWrapper';


const VERSION = packageJSON.version;
// const VERSION = require('../package.json').version;

const theme = createTheme(Theme);

axios.defaults.baseURL = process.env.REACT_APP_API;

axios.defaults.headers.common['Accept-Token'] = btoa(
	Math.round(Math.random() * 1000000) + '|' +
	process.env.REACT_APP_TOKEN + '|' +
	Math.floor(Date.now() / 1000) + '|' +
	VERSION + '|' +
	Math.round(Math.random() * 1000000)
).replace(/=/g, '-') + '.' + VERSION;

const token = Cookies.get('token');
axios.defaults.headers.common['Auth-Token'] = token;
// axios.defaults.validateStatus = (status) => { return status >= 200 && status < 300 }

function App() {

	const dispatch = useDispatch();

	useEffect(() => {

		if (token) dispatch(validateToken({ auth_token: token }));

		const dev_info = `Version: ${VERSION}\nBuilt: ${Moment(preval`module.exports = new Date().getTime()`).format('D MMM, YYYY h:mm a')}`;
		console.log(dev_info);

	}, []);

	/**
	 * 
	 * createRoutesFromElements(
	<Route path="/">
		<Route index element={<Login />} /> // <-- "/"
		<Route
			path="forgot-password             // <-- "/forgot-password"
			element={<ForgotPassword />}
		/>

		<Route element={<RootLayout />}>
			<Route
				path="orders"                   // <-- "/orders"
				element={<Orders />}
			/>
			<Route
				path="dashboard"                // <-- "/dashboard"
				element={<Dashboard />}
			/>
		</Route>
	</Route>
)

	 */

	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route
				path="/"
				errorElement={<RouterError />}
			>

				<Route index element={<WelcomeScreen />} />

				{/* <Route element={ <ThreadsSidebar /> }> */}
				<Route path="/proposals">

					<Route index element={
						<ProposalList
						/>
					} />
					<Route path=":proposalId" element={<Proposals />} />

				</Route>

				<Route path="/templates">

					<Route index element={<TemplateList />} />
					<Route path=":templateId" element={<TemplateEdit />} />

				</Route>

				<Route path="/threads">

					<Route index element={<ThreadsList />} />
					<Route path=":threadId" element={<ThreadsTicket />} />

				</Route>
				{/* </Route> */}

				<Route path="/presentations">
					<Route index element={<Typography sx={{ p: 3 }}>Not available</Typography>} />
					<Route path=":hash" element={<Presentations />} />
				</Route>

			</Route>
		)
	);


	return (
		<ThemeProvider theme={theme}>

			<BrowserRouter>
				<Routes>

					<Route
						path="/"
						errorElement={<RouterError />}
					>

						<Route index element={<WelcomeScreen />} />

						<Route path="/proposals">

							<Route index element={
								<SidebarWrapper header="Proposals">
									<ProposalList />
								</SidebarWrapper>
							} />
							<Route path=":proposalId" element={<Proposals />} />

						</Route>

						<Route path="/templates">

							<Route index element={
								<SidebarWrapper header="Templates">
									<TemplateList />
								</SidebarWrapper>
							} />

							<Route path=":templateId" element={<TemplateEdit />} />

						</Route>

						<Route path="/threads">

							<Route index element={
								<SidebarWrapper header="Threads">
									<ThreadsList />
								</SidebarWrapper>
							} />

							<Route path=":threadId" element={
								<SidebarWrapper header="Threads">
									<ThreadsTicket />
								</SidebarWrapper>
							} />

						</Route>

					</Route>

					<Route
						path="/presentations"
						errorElement={<RouterError />}
					>
						<Route index element={<Typography sx={{ p: 3 }}>Not available</Typography>} />
						<Route path=":hash" element={<Presentations />} />
					</Route>

				</Routes>
			</BrowserRouter>

			{/* <RouterProvider router={router} /> */}
		</ThemeProvider>
	);
}

export default App;
