import axios from 'axios';

export const fetchList = () => {
	return new Promise((resolve, reject) => {
		const url = `/proposals`;

		axios({
			url,
			method: 'get',
		})
			.then(res => {
				resolve(res.data || []);
			})
			.catch(err => {
				reject(err);
			});
	});
};

export const create = (data) => {
	return new Promise((resolve, reject) => {
		const url = `/proposals`;

		axios({
			url,
			method: 'post',
			data
		})
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err);
			});
	});
};

export const duplicate = (proposalId) => {
	return new Promise((resolve, reject) => {
		const url = `/proposals/${proposalId}/duplicate`;
		const data = []
		axios({
			url,
			method: 'post',
			data
		})
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err);
			});
	});
};
export const send = (proposalId, data) => {
	return new Promise((resolve, reject) => {
		const url = `/proposals/${proposalId}/send`;
		axios({
			url,
			method: 'post',
			data
		})
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err);
			});
	});
};

export const deleteOne = (proposalId) => {
	return new Promise((resolve, reject) => {
		const url = `/proposals/${proposalId}`;

		axios({
			url,
			method: 'delete',
		})
			.then(() => {
				resolve(proposalId);
			})
			.catch(err => {
				reject(err);
			});
	});
};

export const load = (proposalId) => {
	return new Promise((resolve, reject) => {
		const url = `/proposals/${proposalId}`;

		axios({
			url,
			method: 'get',
		})
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err);
			});
	});
};

export const save = (proposalId, data) => {
	return new Promise((resolve, reject) => {
		const url = `/proposals/${proposalId}`;

		axios({
			url,
			method: 'PUT',
			data,
		})
			.then(() => {
				resolve(data);
			})
			.catch(err => {
				reject(err);
			});
	});
};

export const createLink = (proposalId, data) => {
	return new Promise((resolve, reject) => {
		const url = `/proposals/${proposalId}/links`;

		axios({
			url,
			method: 'post',
			data
		})
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err);
			});
	});
};
export const deleteLink = (proposalId, linkId) => {
	return new Promise((resolve, reject) => {
		const url = `/proposals/${proposalId}/links/${linkId}`;

		axios({
			url,
			method: 'podeletest',
			data
		})
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err);
			});
	});
};
