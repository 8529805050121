import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';

import { 
	Fab,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	CircularProgress,
} from '@mui/material';

import { ReactComponent as Logo } from '../../assets/logo.svg';
import { promiseContainer } from '../../components';
import { authResult, authResults, authStatus, saveLogin, statuses } from '../../services/auth/slice';
import { useNavigate } from 'react-router-dom';


const WelcomeScreen = props => {

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const propsAuthResult = useSelector(authResult);
	const propsAuthStatus = useSelector(authStatus);

	// https://console.cloud.google.com/apis/dashboard?project=ticketsdatabase
	// https://console.cloud.google.com/apis/credentials?project=ticketsdatabase
	// https://blog.logrocket.com/guide-adding-google-login-react-app/
	const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
	const googleScopes = 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';

	useEffect(() => {

		const initClient = () => {
			gapi.client.init({
				clientId: googleClientId,
				scope: googleScopes,
			});
		};
		gapi.load('client:auth2', initClient);

	}, []);

	useEffect(() => {
		if (propsAuthResult === authResults.authed) {
			navigate('/proposals');
		}
	}, [ propsAuthResult ]);

	const onLogin = (google_response) => {

		if ((google_response.profileObj?.email || '').indexOf('@inboxdesign.co.nz') !== -1) {

			dispatch(saveLogin(google_response.profileObj))
				.unwrap()
				.then(result => {
					if (!result.auth_token) {
						throw new Error;
					}

					// const token = result.auth_token;
					// axios.defaults.headers.common['Auth-Token'] = token;

					setTimeout(() => {
						navigate(`/proposals${window.location.search || ''}`);
					}, 500);
				})
				.catch(err => {
					promiseContainer(({ onConfirm, show }) => (
						<Dialog open={show} onClose={onConfirm}>
							<DialogTitle>Error</DialogTitle>
							<DialogContent>
								<DialogContentText>We failed to login you into your account. Please try it again.</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={onConfirm} color="error">Okay</Button>
							</DialogActions>
						</Dialog>
					));

				});

		} else {
			promiseContainer(({ onConfirm, show }) => (
				<Dialog open={show} onClose={onConfirm}>
					<DialogTitle>Error</DialogTitle>
					<DialogContent>
						<DialogContentText>We failed to login you into your account. Please try it again.</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={onConfirm} color="error">Okay</Button>
					</DialogActions>
				</Dialog>
			))
				.catch(() => { });
		}

	};

	const onFailure = (err) => {
		promiseContainer(({ onConfirm, show }) => (
			<Dialog open={show} onClose={onConfirm}>
				<DialogTitle>Error</DialogTitle>
				<DialogContent>
					<DialogContentText>Signing in with Google failed. Please try it again.</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onConfirm} color="error">Okay</Button>
				</DialogActions>
			</Dialog>
		));
		console.log('Failed:', err);
	};

	const onWebsite = () =>  {
		window.location = 'https://www.inboxdesign.co.nz/';
	}

	return (
		<>
			<div className="d-flex align-items-center vh-100 justify-content-center">
				<div className="d-flex flex-column align-items-center">
					<Logo className="ibdn-logo mb-4" width="240" />
					<h5 className="text-center fw-light mb-4">Please visit our website for more information</h5>
					<Fab
						variant="extended"
						color="primary"
						className="px-5"
						onClick={onWebsite}
					>Inbox Design Website</Fab>
				</div>
			</div>
			<div className="position-fixed bg-white border-top" style={{ bottom: 0, left: 0, right: 0 }}>
				<div className="d-flex flex-column align-items-center py-3">
					{
						propsAuthStatus === statuses.loading ?
							<CircularProgress
								size={24}
								color="primary"
							/>
							:
							<GoogleLogin
								clientId={googleClientId}
								buttonText="Sign in with Google"
								onSuccess={onLogin}
								onFailure={onFailure}
								cookiePolicy={'single_host_origin'}
								isSignedIn={false}
							/>
					}
				</div>
			</div>
		</>
	);
};

export default WelcomeScreen;
