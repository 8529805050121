import React, { useState } from "react";
import { AppBar, Avatar, Box, Button, CssBaseline, Divider, Drawer, Fab, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, Stack, Toolbar, Typography, colors } from "@mui/material";
import { 
	Outbox as IconOutbox,
  Inventory as IconInventory,
  Receipt as IconReceipt,
  Menu as IconMenu,
  Logout as IconLogout,
  Person as IconPerson,
} from '@mui/icons-material';
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { authObject, logout } from "../../services/auth/slice";

const drawerWidth = 240;

interface ISidebarWrapper {
  header: string;
  window: Window;
  children: React.JSX.Element;
};

const SidebarWrapper = (props: ISidebarWrapper) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

	const [avatarMenuAnchorEl, setAvatarMenuAnchorEl] = React.useState(null);
	const avatarMenuOpen = Boolean(avatarMenuAnchorEl);
	const propsAuthObject = useSelector(authObject);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
	const navigate = useNavigate();
  const location = useLocation();

  const container = window !== undefined ? () => window.document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

	const handleAvatarClick = (event) => {
		setAvatarMenuAnchorEl(event.currentTarget);
	};

	const handleCloseAvatarMenu = () => {
		setAvatarMenuAnchorEl(null);
	};

	const handleLogout = () => {
		handleCloseAvatarMenu();

		dispatch(logout())
			.unwrap()
			.finally(() => {
				navigate('/');
			});
	};

  const handleNavigate = (pathname: string) => {
    navigate(pathname || '/');
    setMobileOpen(false);
  }

  /**
   * Creates regular express to match the pathname 
   * @param {string} pathname 
   * @returns {RegExp}
   * @example
   * getPathnameRegexp('/proposals') matches `/proposals`, `/proposals/`, `/proposals/123`
   */
  const getPathnameRegexp = (pathname: string): RegExp => {
    const reg = new RegExp(_.escapeRegExp(pathname) + '(?!\\w)', 'g');
    return reg;
  };

  const isSelected = (pathname: string): boolean => {
    return getPathnameRegexp(pathname).test(location.pathname);
  };

  const drawer = (
    <Stack direction="column" justifyContent="space-between" sx={{ height: '100%', backgroundColor: colors.grey[50] }}>
      <div>

        <List
          subheader={<ListSubheader sx={{ backgroundColor: colors.grey[50] }}>Proposals</ListSubheader>}
        >

          <ListItem disablePadding>
            <ListItemButton onClick={ () => handleNavigate('/proposals') } selected={ isSelected('/proposals') }>
              <ListItemIcon>
                <IconOutbox />
              </ListItemIcon>
              <ListItemText primary="Proposals" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton onClick={ () => handleNavigate('/templates') } selected={ isSelected('/templates') }>
              <ListItemIcon>
                <IconInventory />
              </ListItemIcon>
              <ListItemText primary="Templates" />
            </ListItemButton>
          </ListItem>

        </List>

        <List
          subheader={<ListSubheader sx={{ backgroundColor: colors.grey[50] }}>Tickets</ListSubheader>}
        >
          <ListItem disablePadding>
            <ListItemButton onClick={ () => handleNavigate('/threads') } selected={ isSelected('/threads') }>
              <ListItemIcon>
                <IconReceipt />
              </ListItemIcon>
              <ListItemText primary="Tickets" />
            </ListItemButton>
          </ListItem>
        </List>

      </div>

      <div>
        <Divider />

        <List>

          <ListItem disablePadding>

              <Button
                id="basic-button"
                aria-controls={avatarMenuOpen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={avatarMenuOpen ? 'true' : undefined}
                onClick={handleAvatarClick}
                color="secondary"
                sx={{ borderRadius: 0, width: '100%', justifyContent: 'start' }}
              >
                <Avatar sx={{ mx: 1 }}>
                  {propsAuthObject?.auth_name?.[0] || <IconPerson />}
                </Avatar>

                <Typography color={colors.grey[900]} sx={{ textTransform: 'initial' }} fontWeight={ 500 }>{propsAuthObject?.auth_name}</Typography>
              </Button>

              <Menu
                id="basic-menu"
                anchorEl={avatarMenuAnchorEl}
                open={avatarMenuOpen}
                onClose={handleCloseAvatarMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <MenuItem disabled divider>
                  <Stack>
                    <Typography sx={{ fontWeight: '700' }}>{propsAuthObject?.auth_name}</Typography>
                    <Typography>{propsAuthObject?.auth_email}</Typography>
                  </Stack>
                </MenuItem>

                <MenuItem onClick={handleLogout}>
                  <IconLogout sx={{ mr: 1 }} />
                  Logout
                </MenuItem>
              </Menu>

          </ListItem>

        </List>

      </div>

    </Stack>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { md: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{ flexGrow: 1, width: { lg: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Stack direction="column">

          <AppBar
            position="sticky"
            sx={{
              top: 0,
              width: { lg: `calc(100% - ${drawerWidth}px)` },
              ml: { lg: `${drawerWidth}px` },
              display: { lg: 'none' }
            }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
              >
                <IconMenu />
              </IconButton>

              <Typography variant="h6" noWrap component="div">{ props.header || '' }</Typography>

            </Toolbar>
          </AppBar>

          { props.children }

        </Stack>
      </Box>
    </Box>
  );
};

export default SidebarWrapper;