import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from '../store';
import { PropTypes } from 'prop-types';

const destroy_delay = 1000;

const promiseContainer = (func) => {
	const container = document.createElement('div');
	const root = createRoot(container);
	document.body.appendChild(container);

	function AppWithCallbackAfterRender(props) {
		useEffect(() => {
			props?.callback();
		});

		return (
			<Provider store={store}>
				{func({ onConfirm: props.onConfirm, onDismiss: props.onDismiss, show: false })}
			</Provider>
		);
	}

	AppWithCallbackAfterRender.propTypes = {
		onConfirm: PropTypes.func,
		onDismiss: PropTypes.func,
		callback: PropTypes.func,
	};

	function show({ onConfirm, onDismiss }) {
		root.render(
			<Provider store={store}>
				{func({ onConfirm, onDismiss, show: true })}
			</Provider>
		);
	}

	function hide({ onConfirm, onDismiss }, callback) {
		root.render(
			<AppWithCallbackAfterRender onConfirm={ onConfirm } onDismiss={ onDismiss } callback={ callback } />
		);
	}

	function destroy() {
		root.unmount();
		document.body.removeChild(container);
	}

	const confirmation = new Promise((resolve, reject) => {
		const onConfirm = (value) => resolve(value);
		const onDismiss = () => reject('dismissed');
		show({ onConfirm, onDismiss });
	});

	return confirmation.finally(() => {
		const emptyFunc = () => {};
		const onConfirm = emptyFunc;
		const onDismiss = emptyFunc;

		hide({ onConfirm, onDismiss }, () => {
			setTimeout(destroy, destroy_delay);
		});
	});
};

export default promiseContainer;
