import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { 
	Box,
	Button,
	Container,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Fab,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	ListItemButton,
	IconButton,
	Tab,
	Tabs,
	Chip,
	Stack,
	Typography,
	Menu,
	MenuItem,
	Badge,
	Avatar,
} from '@mui/material';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { Link, useNavigate } from "react-router-dom";

import { 
	loadThreadsList,
	threadList,
	createThread,
	threadStatus,
	statuses,
} from '../../services/threads/slice';

import { 
	Add as IconAdd, 
	MoreHoriz as IconMoreHoriz,
	Edit as IconEdit,
	Delete as IconDelete,
	Logout as IconLogout,
	Person as IconPerson,
} from '@mui/icons-material';
import MailIcon from '@mui/icons-material/Mail';

import Moment from 'moment';

import { promiseContainer } from '../../components';
import { authResult, authResults, authObject, logout } from '../../services/auth/slice';
import { ThunkDispatch } from '@reduxjs/toolkit';



const ThreadsList = props => {
    
	const propsAuthResult = useSelector(authResult);
	const propsAuthObject = useSelector(authObject);
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState('threads');

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

	const [avatarMenuAnchorEl, setAvatarMenuAnchorEl] = React.useState(null);
	const avatarMenuOpen = Boolean(avatarMenuAnchorEl);
    
	useEffect(() => {
		if (propsAuthResult === authResults.failed) {
			navigate('/');
		}
	}, [ propsAuthResult ]);

	// const list = useSelector(threadList) || [];
	const [ list, setList ] = useState([]);
	const status = useSelector(threadStatus);


	const columns = [
		{
			field: 'thread_subject',
			headerName: 'Subject',
			minWidth: 600,
			renderCell: (params) => {
				return (
					<Link to={`/threads/${params.row.thread_id}`}>{params.row.thread_subject}</Link>
				)
			}
		},
		{
			field: 'message_count',
			headerName: 'Messages',
			type: 'number',
			width: 60,
			renderCell: (params) => {
				return (
					<Badge badgeContent={params.row.message_count} color="primary">
						<MailIcon color="action" />
					</Badge>
				)
			}
		},
		{
			field: 'thread_status',
			headerName: 'Status',
			width: 80,
			renderCell: (params) => {
				let color;
				let variant;
				switch (params.row.thread_status?.toLowerCase()) {
					case 'closed':
						color = 'success';
						variant = 'filled';
						break;
					case 'late':
						color = 'error';
						variant = 'filled';
						break;
					case 'open':
						color = 'warning';
						variant = 'filled';
						break;
					default:
						color = 'default';
						variant = 'outlined';
						break;
				}
				return (
					<Chip label={params.row.thread_status} size="small" sx={{ mr: 1, textTransform: 'uppercase' }}
						variant={variant}
						color={color}
					/>
				)
			}
		},
		{
			field: 'date_updated',
			headerName: 'Last Updated',
			description: 'Last Updated',
			width: 150,
			renderCell: (params) => {
				return (
					<Box>
						<strong className="d-block">{Moment(params.row.date_updated).fromNow()}</strong>
						<small className="d-block">{Moment(params.row.date_updated).format('dddd D MMM, YYYY h:mm A')}</small>
					</Box>
				)
			}
		}
	];

	const onNewThread = () => {

	}

	const newThread = () => {
		dispatch(createThread({
			thread_subject: '',
			recipients: [],
			thread_content: '',
		}))
			.then(res => {
				navigate(`/threads/${res.payload}`);
			})
			.catch(err => {
				promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
					open={show}
					onClose={onDismiss}
				>
					<DialogTitle>Failed to create a new thread</DialogTitle>
					<DialogContent>
						<DialogContentText>{err}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={onDismiss}>Cancel</Button>
						<Button onClick={onConfirm} autoFocus>Retry</Button>
					</DialogActions>
				</Dialog>
				)
					.then(() => { })
					.catch(() => { });
			});
	};

	useEffect(() => {
		const load = () => {
			dispatch(loadThreadsList())
				.unwrap()
				.then(data => {
					setList(data);
				})
				.catch(err => {
					promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
						open={show}
						onClose={onDismiss}
					>
						<DialogTitle>Failed to load the proposal template list</DialogTitle>
						<DialogContent>
							<DialogContentText>{err}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={onDismiss}>Cancel</Button>
							<Button onClick={onConfirm} autoFocus>Retry</Button>
						</DialogActions>
					</Dialog>
					)
						.then(() => {
							load();
						})
						.catch(() => { });
				});
		};
		load();
	}, []);

	const handleAvatarClick = (event) => {
		setAvatarMenuAnchorEl(event.currentTarget);
	};

	const handleCloseAvatarMenu = () => {
		setAvatarMenuAnchorEl(null);
	};

	const handleLogout = () => {
		handleCloseAvatarMenu();

		dispatch(logout())
			.unwrap()
			.finally(() => {
				navigate('/');
			});
	};

	const handleRowClick = (
		params, // GridRowParams
		event, // MuiEvent<React.MouseEvent<HTMLElement>>
		details, // GridCallbackDetails
	) => {
		console.log(params);
		console.log(event);
		console.log(details);
		
		// getSelectedRows
	};

	return (
		<Container>

			<Stack flexDirection="row" alignItems="center" justifyContent="space-between">

				<Box>

					<Fab
						variant="extended"
						color="primary"
						size="medium"
						onClick={onNewThread}
						disabled={status === statuses.saving}
					><IconAdd sx={{ mr: 1 }} />New Thread</Fab>

					<Menu
						id="basic-menu"
						anchorEl={avatarMenuAnchorEl}
						open={avatarMenuOpen}
						onClose={handleCloseAvatarMenu}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<MenuItem disabled divider>
							<Stack>
								<Typography sx={{ fontWeight: '700' }}>{ propsAuthObject?.auth_name }</Typography>
								<Typography>{ propsAuthObject?.auth_email }</Typography>
							</Stack>
						</MenuItem>

						<MenuItem onClick={handleLogout}>
							<IconLogout sx={ { mr: 1 } } />
							Logout
						</MenuItem>
					</Menu>
				</Box>

			</Stack>

			<Stack>
				<DataGrid
					sx={{ my: 4 }}
					rows={list}
					columns={columns}
					loading={list.length === 0}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 25,
							},
						},
					}}
					slots={{ toolbar: GridToolbar }}
					slotProps={{
					toolbar: {
						showQuickFilter: true,
					},
					}}
					getRowId={(row) => row.thread_id}
					onRowClick={handleRowClick}
					pageSizeOptions={[10, 25, 50, 100]}
					checkboxSelection
					disableRowSelectionOnClick
					onSelectionModelChange={(ids) => {
						const selectedIDs = new Set(ids);
						const selectedRowData = list.filter((row) =>
							selectedIDs.has(row.id.toString())
						);
						// console.log(selectedRowData);
					}}
				/>
			</Stack>
		</Container>
	);
};

export default ThreadsList;