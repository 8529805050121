const key = 'gVG3C-8F2G2C5A3C3D4ud1BI1IMNBUMRWAi1AYMSTRBUZYB-16D4E3D2B2C3I2G1B10D3A1==';

export const defaultConfig = {
	attribution: false,
	key, 
	toolbarButtons: {
		'moreText': {
			'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
		},
		'moreParagraph': {
			'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
		},
	
		'moreRich': {
			'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
		},
		'moreMisc': {
			'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
			'align': 'right',
			'buttonsVisible': 2
		}
	}
};