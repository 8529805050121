import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { 
	AppBar,
	Alert,
	Box,
	Container,
	CircularProgress,
	Badge,
	Button,
	ButtonGroup,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	DialogContentText,
	IconButton,
	InputAdornment,
	InputBase,
	Toolbar,
	Typography,
	Menu, 
	MenuItem,
	ListItemIcon,
	ListItemText,
	Slide,
	Stack,
	Snackbar,
	Paper,
	Chip,
	colors,
} from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import Moment from 'moment';
import _ from 'lodash';
import * as html2pdf from 'html2pdf.js';
import { useBeforeunload } from 'react-beforeunload';

import { 
	ArrowBack as IconArrowBack, 
	LiveHelp as IconLiveHelp, 
	Send as IconSend,
	Save as IconSave,
	Download as IconDownload,
	Link as IconLink,
	ArrowDropDown as IconArrowDropDown,
	ContentCopy as IconContentCopy,
	Edit as IconEdit,
	Close as IconClose,
} from '@mui/icons-material';

import { defaultProposalTitle } from "../../constants";

import {
	promiseContainer,
	DocumentBuilder,
} from "../../components";
import { 
	loadProposal,
	saveProposal,
	duplicateProposal,
	proposal,
	proposalStatus,
	statuses,
} from '../../services/proposals/slice';
import classNames from "classnames";
import DocumentViewer from "../../components/documentViewer";

import { authResult, authResults } from "../../services/auth/slice";
import Links from "./links";

const enableAutosave = false;

export const Proposals = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const propsProposal = useSelector(proposal);

	const status = useSelector(proposalStatus);
	const propsAuthResult = useSelector(authResult);

	const [sendShow, setSendShow] = useState(false);
	const [PDFGenerating, setPDFGenerating] = useState(false);

	const [saveMenuAnchorEl, setSaveMenuAnchorEl] = useState(null);

	const [proposalData, setProposalData] = useState(default_proposal_info);

	const inputNameRef = useRef(null);
	const { proposalId } = useParams();

	useEffect(() => {
		if (propsAuthResult === authResults.failed) navigate('/');
	}, [ propsAuthResult ]);

	const unsaved = useMemo(() => {

		const nameDiff = proposalData?.proposal_name !== propsProposal?.proposal_name;
		const htmlDiff = proposalData?.proposal_html !== propsProposal?.proposal_html;
		const dataDiff = !_.isEqual(proposalData.proposal_data, propsProposal?.proposal_data);

		const isUnsaved = (nameDiff || htmlDiff || dataDiff);
		return isUnsaved;
	}, [proposalData, propsProposal]);

	useEffect(() => {
		loadData();
	}, [proposalId]);

	useBeforeunload(
		unsaved ? (event) => event.preventDefault() : null
	);


	const beforeNavigateAway = () => {

		const goBack = () => {
			navigate(`/proposals/${window.location.search || ''}`, { replace: true });
		};

		if (unsaved) {
			promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
				open={show}
				onClose={onDismiss}
			>
				<DialogTitle>Leave the proposal?</DialogTitle>
				<DialogContent>
					<DialogContentText>Changes that you made haven not been saved.</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onDismiss} autoFocus>Cancel</Button>
					<Button onClick={onConfirm} color="error">Leave</Button>
				</DialogActions>
			</Dialog>
			)
				.then(() => {
					goBack();
				})
				.catch(() => { });
		} else {
			goBack();
		}
	};

	const onShowSchoolInfo = () => {

		promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
			open={show}
			onClose={onDismiss}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle>{propsProposal?.school?.school_name || ''}</DialogTitle>
			<DialogContent>
				<DialogContentText>

					<table>
						<tbody>

							<tr>
								<td className="pe-3">MOE</td>
								<td>{propsProposal?.school?.school_id || ''}</td>
							</tr>

							<tr>
								<td className="pe-3">Phone</td>
								<td>{propsProposal?.school?.school_phone || ''}</td>
							</tr>

							<tr>
								<td className="pe-3">Address</td>
								<td>{propsProposal?.school?.school_physical_address || ''}</td>
							</tr>

						</tbody>
					</table>

				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onDismiss} autoFocus>Close</Button>
			</DialogActions>
		</Dialog>
		)
			.then(() => { })
			.catch(() => { });
	};

	const loadData = () => {

		if (proposalId) {

			const load = () => {
				dispatch(loadProposal({ proposalId }))
					.unwrap()
					.then(res => {
						const { proposal_name = '', proposal_html = '', proposal_data = {} } = res;

						setProposalData({
							proposal_name,
							proposal_html,
							proposal_data,
						});
					})
					.catch(err => {
						promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
							open={show}
							onClose={onDismiss}
						>
							<DialogTitle>Failed to load the proposal</DialogTitle>
							<DialogContent>
								<DialogContentText>{err}</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={onDismiss}>Cancel</Button>
								<Button onClick={onConfirm} autoFocus>Retry</Button>
							</DialogActions>
						</Dialog>
						)
							.then(() => {
								load();
							})
							.catch(() => { });
					});
			};

			load();
		}
	};

	const toggleSaveMenu = e => {
		setSaveMenuAnchorEl(!saveMenuAnchorEl ? e.currentTarget : null);
	};

	useEffect(() => {
		if(!enableAutosave) return;

		const debounce = _.debounce(() => {
			if(unsaved) {
				handleSave(true);
				console.log('Autosaved at', new Date());
			}
		}, 5000);

		debounce();
		return debounce.cancel;
	}, [unsaved, proposalData, propsProposal]);
	
	const handleSave = (isAutosave = false) => {
		const save = () => {

			dispatch(saveProposal({
				proposalId, 
				data: {
					proposal_name: proposalData.proposal_name,
					proposal_html: proposalData.proposal_html,
					proposal_data: proposalData.proposal_data,
				}
			}))
				.unwrap()
				.then(() => {
					promiseContainer(({ onDismiss, show }) => <Snackbar open={show} autoHideDuration={2000}
						onClose={onDismiss}
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						TransitionComponent={p => <Slide {...p} direction="left" />}
					>
						<Alert onClose={onDismiss} severity="success" sx={{ width: '100%' }}>
							{ isAutosave === true ? 'Proposal saved automatically' : 'Proposal saved' }
						</Alert>
					</Snackbar>
					)
						.catch(() => { });
				})
				.catch(err => {
					if (isAutosave) {
						promiseContainer(({ onDismiss, show }) => <Snackbar open={show} autoHideDuration={isAutosave ? 5000 : 2000}
							onClose={onDismiss}
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
							TransitionComponent={p => <Slide {...p} direction="left" />}
						>
							<Alert onClose={onDismiss} severity="error" sx={{ width: '100%' }}>Failed to autosave the proposal. Please try again manually.</Alert>
						</Snackbar>
						)
							.catch(() => { });
					}else{
						promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
							open={show}
							onClose={onDismiss}
						>
							<DialogTitle>Failed to save the proposal</DialogTitle>
							<DialogContent>
								<DialogContentText>{err}</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={onDismiss}>Cancel</Button>
								<Button onClick={onConfirm} autoFocus>Retry</Button>
							</DialogActions>
						</Dialog>
						)
							.then(() => {
								save();
							})
							.catch(() => { });
					}
				});
		};

		if (!proposalData?.proposal_name?.trim()?.length) {
			promiseContainer(({ onDismiss, show }) => <Dialog
				open={show}
				onClose={onDismiss}
			>
				<DialogTitle>Warning</DialogTitle>
				<DialogContent>
					<DialogContentText>Please input the proposal name</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onDismiss}>OK</Button>
				</DialogActions>
			</Dialog>
			).catch(() => {
				inputNameRef.current?.focus();
			});
		} else {
			save();
		}
	};

	const handleDuplicateProposal = () => {
		toggleSaveMenu();
		dispatch(duplicateProposal({
			proposalId
		}))
			.unwrap()
			.then(res => {
				promiseContainer(({ onDismiss, show }) => <Snackbar open={show} autoHideDuration={2000}
					onClose={onDismiss}
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					TransitionComponent={p => <Slide {...p} direction="left" />}
				>
					<Alert onClose={onDismiss} severity="success" sx={{ width: '100%' }}>
						Saved the proposal as new
					</Alert>
				</Snackbar>
				)
					.catch(() => { });

				if (res?.id) {
					navigate(`/proposals/${res?.id}${window.location.search || ''}`, { replace: true });
				} else {
					navigate(`/proposals/${window.location.search || ''}`, { replace: true });
				}
			})
			.catch(err => {
				promiseContainer(({ onDismiss, show }) => <Dialog
					open={show}
					onClose={onDismiss}
				>
					<DialogTitle>Failed to duplicate the Proposal</DialogTitle>
					<DialogContent>
						<DialogContentText>{err}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={onDismiss}>Okay</Button>
					</DialogActions>
				</Dialog>
				)
					.catch(() => { });
			});
	};

	const handleExportPDF = () => {
		setPDFGenerating(true);
		const pdf_el = document.querySelector('#export-element');
		const footer_height = 16;
		const options = {
			filename: `${proposalData?.proposal_name || 'Inbox Design Proposal'} - ${propsProposal?.school?.school_name || ''} - ${Moment().format('D MMMM, YYYY')}.pdf`,
			margin: [ 4, 0, footer_height, 0 ],
			image: { type: 'jpeg', quality: .75 },
			pagebreak: {
				mode: ['css', 'legacy'],
				before: '.break-before',
				after: '.break-after',
				avoid: '.avoid-break'
			},
			html2canvas: {
				scale: 4,
				useCORS: true,
			},
			jsPDF: {
				format: 'A4',
				orientation: 'portrait',
			},
		};

		pdf_el.querySelectorAll('.no-print').forEach(el => el.style.display = 'none');

		html2pdf()
			.from(pdf_el)
			.set(options)
			.toPdf()
			.get('pdf')
			.then(pdf => {
				pdf_el.querySelectorAll('.no-print').forEach(el => el.style.display = 'block');
				var totalPages = pdf.internal.getNumberOfPages();

				for (let i = 1; i <= totalPages; i++) {

					const txt_title = proposalData?.proposal_name || defaultProposalTitle;
					const page_width = pdf.internal.pageSize.getWidth();
					const page_height = pdf.internal.pageSize.getHeight();

					pdf.setDrawColor(225);
					pdf.setLineWidth(.1);
					pdf.line(0, page_height - footer_height, page_width, page_height - footer_height);

					pdf.setPage(i);
					pdf.setFontSize(8);

					pdf.setTextColor(50);
					pdf.text(
						txt_title, 
						8, 
						page_height - footer_height / 3 - 4,
						{
							align: 'left'
						}
					);

					pdf.setTextColor(150);
					// pdf.text(
					// 	`| ${proposalData?.school?.school_name || ''}`, 
					// 	pdf.getTextDimensions(txt_title).w + 10, 
					// 	page_height - footer_height / 3 - 4,
					// 	{
					// 		align: 'left'
					// 	}
					// );
					pdf.text(
						`Page ${i} of ${totalPages}`, 
						page_width - 8, 
						page_height - footer_height / 3 - 4,
						{
							align: 'right'
						}
					);
				}
			})
			.save()
			.finally(() => {
				setPDFGenerating(false);
			});
	};

	const handleExportOpen = () => {
		setSendShow(true);
	};

	const handleExportClose = () => {
		setSendShow(false);
	};

	const handleOpenLinks = () => {
		promiseContainer(({ onConfirm, show }) => <Links
			{...{ show, onConfirm } }
		/>
		)
	};

	return (
		<>
			<Dialog
				fullScreen
				open={sendShow}
				onClose={handleExportClose}
				TransitionComponent={Transition}
			>
				<Stack direction="column" flex={1}>

					<AppBar sx={{ position: 'relative', flexGrow: 0 }}>
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								onClick={handleExportClose}
								aria-label="close"
							>
								<IconClose />
							</IconButton>

							<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Review Proposal</Typography>


							<Badge badgeContent={(propsProposal.links || []).length} color="warning" sx={{ ml: 1 }}>
								<Button autoFocus color="inherit" onClick={ handleOpenLinks } >
									<IconLink size="small" sx={{ mr: 1 }} />
									Share Links
								</Button>
							</Badge>

							<Button autoFocus color="inherit" onClick={handleExportPDF} disabled={PDFGenerating} sx={{ ml: 1 }}>
								<IconDownload size="small" sx={{ mr: 1 }} />
								Download PDF
							</Button>
						</Toolbar>
					</AppBar>

					<Box sx={{ flexGrow: 1, backgroundColor: colors.grey[300], width: '100%', height: '100%', position: 'relative' }}>
						<Box sx={{ position: 'absolute', width: '100%', height: '100%', overflowY: 'auto' }}>
							<Container maxWidth="md" sx={{ my: 4 }}>
								<Paper elevation={8} square>
									<div id="export-element">
										{
											sendShow &&
												<DocumentViewer
													htmlTemplate={proposalData.proposal_html}
													data={proposalData.proposal_data}
													editableFields={false}
												/>
										}
									</div>
								</Paper>
							</Container>
						</Box>
					</Box>

				</Stack>

			</Dialog>

			<Stack direction="column" sx={{ height: '100%' }}>

				<Box sx={{ flexGrow: 0, flexShrink: 0, zIndex: 1200 }}>
					<AppBar sx={{ position: 'static' }} color="white" elevation={0}>
						<Toolbar>
							<Stack sx={{ flexGrow: 1 }} direction="row" alignItems="center">

								<IconButton
									edge="start"
									color="default"
									sx={{ flexGrow: 0 }}
									onClick={beforeNavigateAway}
								>
									<IconArrowBack />
								</IconButton>

								{
									status !== statuses.loading &&
									<Stack sx={{ flexGrow: 1 }}>
										<>
											{
												propsProposal?.status?.toLowerCase() === 'draft' ?
													<InputBase
														inputRef={inputNameRef}
														autoFocus
														sx={{ fontWeight: 900, borderRadius: 1 }}
														startAdornment={
															<InputAdornment position="start" sx={{ pl: 1 }}>
																<IconEdit fontSize="small" />
															</InputAdornment>
														}
														error={!proposalData?.proposal_name?.trim()?.length}
														placeholder="Input proposal title"
														value={proposalData?.proposal_name || ''}
														onChange={e => {
															setProposalData(prevState => {
																prevState = {
																	...prevState,
																	proposal_name: e.target.value,
																};
																return prevState;
															});
														}}
													/>
													:
													<Stack flexDirection="row">
															<Typography color="grey" nowrap
																sx={{
																	fontWeight: '600',
																	overflow: 'hidden',
																	textOverflow: 'ellipsis',
																	whiteSpace: 'nowrap',
																	maxWidth: '360px',
																}}
															>{propsProposal?.proposal_name || ''}</Typography>

														{(() => {
															let color;
															let variant;
															switch (propsProposal.status?.toLowerCase()) {
																case 'approved':
																	color = 'success';
																	variant = 'filled';
																	break;
																case 'declined':
																	color = 'error';
																	variant = 'filled';
																	break;
																case 'viewed':
																	color = 'warning';
																	variant = 'filled';
																	break;
																default: '';
																	color = 'default';
																	variant = 'outlined';
																	break;
															}

															return (
																<Chip label={propsProposal.status} size="small" sx={{ ml: 1, textTransform: 'uppercase' }}
																	variant={ variant }
																	color={ color }
																/>
															);
														})()}

													</Stack>
											}
										</>
									</Stack>
								}
							</Stack>

							<Box sx={{
								flexGrow: 0,
								display: {
									md: 'flex',
									sm: 'none',
									xs: 'none',
								},
								alignItems: 'center'
							}}>

								<IconButton
									edge="start"
									color="default"
									sx={{ flexGrow: 0 }}
									onClick={onShowSchoolInfo}
								>
									<IconLiveHelp />
								</IconButton>

								{
									propsProposal?.status?.toLowerCase() === 'draft' ?
										<React.Fragment>
											<Button variant="outlined" color="primary" size="small" sx={{ ml: 1, borderRadius: '4rem' }} disabled={status === statuses.loading} onClick={handleExportOpen}>
												<IconSend size="small" sx={{ mr: 1 }} />
												Send
											</Button>

											<Badge color="warning" variant="dot" invisible={!unsaved || status === statuses.loading}>
												<ButtonGroup variant="contained" color="primary" aria-label="Save" sx={{ ml: 1, borderRadius: '4rem' }} disabled={status === statuses.loading || status === statuses.saving}>

													<Button size="small" onClick={handleSave} sx={{ borderRadius: '4rem' }}>
														{
															status === statuses.saving ?
																<CircularProgress size={24} color="primary" sx={{ mr: 1 }} />
																:
																<IconSave size="small" sx={{ mr: 1 }} />
														}
														Save Proposal
													</Button>

													<Button
														size="small"
														aria-controls={saveMenuAnchorEl ? 'split-button-menu' : undefined}
														aria-expanded={saveMenuAnchorEl ? 'true' : undefined}
														aria-label="more saving options"
														aria-haspopup="menu"
														sx={{ px: 0, borderRadius: '4rem' }}
														onClick={toggleSaveMenu}
													>
														<IconArrowDropDown />
													</Button>
													<Menu
														anchorEl={saveMenuAnchorEl}
														open={Boolean(saveMenuAnchorEl)}
														onClose={toggleSaveMenu}
														anchorOrigin={{
															vertical: 'bottom',
															horizontal: 'right',
														}}
														transformOrigin={{
															vertical: 'top',
															horizontal: 'right',
														}}
													>
														<MenuItem disabled={status === statuses.saving || status === statuses.loading} onClick={handleDuplicateProposal}>
															<ListItemIcon><IconContentCopy fontSize="small" /></ListItemIcon>
															<ListItemText>Duplicate Proposal</ListItemText>
														</MenuItem>
													</Menu>
												</ButtonGroup>
											</Badge>
										</React.Fragment>
										:
										<React.Fragment>
											<Badge badgeContent={(propsProposal.links || []).length} color="warning" sx={{ ml: 1 }}>
												<Button autoFocus color="secondary" onClick={handleOpenLinks} >
													<IconLink size="small" sx={{ mr: 1 }} />
													Share Links
												</Button>
											</Badge>

											<Button autoFocus color="secondary" onClick={handleExportPDF} disabled={PDFGenerating} sx={{ ml: 1 }}>
												<IconDownload size="small" sx={{ mr: 1 }} />
												Download PDF
											</Button>
										</React.Fragment>
								}

							</Box>

						</Toolbar>
					</AppBar>
				</Box>

				<Box sx={{ flexGrow: 1, flexShrink: 1, position: 'relative' }}>
					<Box sx={{ position: 'absolute', width: '100%', height: '100%', overflow: 'auto' }}>

						{
							status === statuses.loading ?
								<Stack alignItems="center" justifyContent="center" sx={{ height: '100%', flexGrow: 1 }}>
									<CircularProgress size={32} />
								</Stack>
								:
								<React.Fragment>
									{
										propsProposal?.status?.toLowerCase() === 'draft' ?
											<Box className={classNames({ 'disabled': status === statuses.loading })}>
												{
													proposalData.proposal_html && proposalData.proposal_data &&
													<DocumentBuilder
														className="mb-3"
														htmlTemplate={proposalData.proposal_html}
														data={proposalData.proposal_data}
														onContentChange={val => setProposalData(prevState => ({
															...prevState,
															proposal_html: val,
														}))}
														onDataChange={val => setProposalData(prevState => ({
															...prevState,
															proposal_data: val,
														}))}
													/>
												}
											</Box>
											:
											<Box sx={{ flexGrow: 1, backgroundColor: colors.grey[300], width: '100%', height: '100%', position: 'relative' }}>
												<Box sx={{ position: 'absolute', width: '100%', height: '100%', overflowY: 'auto' }}>
													<Container maxWidth="md" sx={{ my: 4 }}>
														<Paper elevation={8} square>
															<div id="export-element">
																<DocumentViewer
																	htmlTemplate={proposalData.proposal_html}
																	data={proposalData.proposal_data}
																	editableFields={false}
																/>
															</div>
														</Paper>
													</Container>
												</Box>
											</Box>
									}
								</React.Fragment>
						}

					</Box>
				</Box>
			</Stack>

		</>
	);
};

const default_proposal_info = {
	proposal_name: "",
	proposal_html: "",
	proposal_data: {},
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});