import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { 
	fetchListAPI, 
	createThreadAPI,
	featchThreadAPI,
	sendMessageAPI,
	saveNoteAPI,
} from "./api";

export const statuses = {
	idle: 'IDLE',
	loading: 'LOADING',
	loaded: 'LOADED',
	saving: 'SAVING',
	deleting: 'DELETING',
	saved: 'SAVED',
	error: 'ERROR',
};

const initialState = {
	list: [],
	status: statuses.idle,
};

export const loadThread = createAsyncThunk(
	'template/loadThread',
	async (threadId, { rejectWithValue }) => {
		try{
			const response = await featchThreadAPI(threadId);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const loadThreadsList = createAsyncThunk(
	'threads/fetchList',
	async (_, { rejectWithValue }) => {
		try{
			const response = await fetchListAPI();
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);


export const createThread = createAsyncThunk(
	'template/create',
	/**
	 * 
	 * @param {object} data 
	 */
	async (data, { rejectWithValue }) => {
		try{
			const response = await createThreadAPI(data);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);


export const sendMessage = createAsyncThunk(
	'template/send',
	/**
	 * 
	 * @param {object} data 
	 */
	async (data, { rejectWithValue }) => {
		try{
			const response = await sendMessageAPI(data);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);


export const saveNote = createAsyncThunk(
	'template/send',
	/**
	 * 
	 * @param {object} data 
	 */
	async (data, { rejectWithValue }) => {
		try{
			const response = await saveNoteAPI(data);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);



export const threadSlice = createSlice({
	name: 'templates',
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(loadThreadsList.pending, state => {
				state.status = statuses.loading;
			}).addCase(loadThreadsList.fulfilled, (state, action) => {
				state.status = statuses.loaded;
				state.list = action.payload;
			}).addCase(loadThreadsList.rejected, state => {
				state.status = statuses.error;
			});
	}
});

export const threads = createSlice({
	name: 'threads',
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(loadThreadsList.pending, state => {
				state.status = statuses.loading;
			}).addCase(loadThreadsList.fulfilled, (state, action) => {
				state.status = statuses.loaded;
				state.list = action.payload;
			}).addCase(loadThreadsList.rejected, state => {
				state.status = statuses.error;
			});
	}
});

export const threadData = (state) => state.threads;
export const threadList = (state) => state.list;
export const threadStatus = (state) => state.threads?.status;

export default threadSlice.reducer;