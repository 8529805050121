import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { 
	load,
	save,
} from "./api";

export const statuses = {
	idle: 'IDLE',
	loading: 'LOADING',
	loaded: 'LOADED',
	saving: 'SAVING',
	deleting: 'DELETING',
	saved: 'SAVED',
	error: 'ERROR',
};

const initialState = {
	presentation: {},
	status: statuses.idle,
};

export const savePresentation = createAsyncThunk(
	'presentation/save',

	/**
	 * @param {object} data 
	 */
	async ({ hash, data }, { rejectWithValue }) => {
		try{
			const response = await save(hash, data);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const loadPresentation = createAsyncThunk(
	'presentation/load',
	/**
	 * @param {string} hash 
	 */
	async (hash, { rejectWithValue }) => {
		try{
			const response = await load(hash);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const presentationSlice = createSlice({
	name: 'presentations',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(loadPresentation.pending, state => {
				state.status = statuses.loading;
				state.presentation = {};
			}).addCase(loadPresentation.fulfilled, (state, action) => {
				state.presentation = action.payload;
				state.status = statuses.loaded;
			}).addCase(loadPresentation.rejected, state => {
				state.status = statuses.error;
			})
			
			.addCase(savePresentation.pending, state => {
				state.status = statuses.saving;
			}).addCase(savePresentation.fulfilled, (state, action) => {
				state.status = statuses.saved;
				state.presentation = { ...state.presentation, ...action.payload || {} };
			}).addCase(savePresentation.rejected, state => {
				state.status = statuses.error;
			});
	}
});

export const presentation = (state) => state.presentations?.presentation;
export const presentationStatus = (state) => state.presentations?.status;

export default presentationSlice.reducer;