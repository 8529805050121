import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { Allotment } from "allotment";
import "allotment/dist/style.css";

import ReactJson from 'react-json-view';

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Stack,
	Typography,
	AppBar,
	Toolbar,
	Container,
	Paper,
	colors,
	OutlinedInput,
	CircularProgress,
	Badge,
	Snackbar,
	Alert,
	Slide,
} from '@mui/material';
import Editor from '@monaco-editor/react';
import lodash from 'lodash';
import randomid from 'randomid';

import { useNavigate, useParams } from "react-router-dom";

import {
	ArrowBack as IconArrowBack,
	Sync as IconSync,
	Save as IconSave,
	DataObjectOutlined as IconDataObject,
	TipsAndUpdatesOutlined as IconTipsAndUpdates,
} from '@mui/icons-material';

import {
	loadTemplate,
	saveTemplate,
	templateData as propsTemplateData,
	templateStatus,
	statuses,
} from '../../services/templates/slice';

import { promiseContainer } from '../../components';
import { authResult, authResults } from '../../services/auth/slice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import DocumentViewer from '../../components/documentViewer';
import styled from 'styled-components';
import { useBeforeunload } from 'react-beforeunload';

const TemplateEdit = props => {

	const [templateHTML, setTemplateHTML] = useState<string>('');
	const [cachedTemplateHTML, setCachedTemplateHTML] = useState<string>('');
	const [templateName, setTemplateName] = useState<string>('');
	const [templateData, setTemplateData] = useState<Record<string, any>>({});
	const [documentViewerKey, setDocumentViewerKey] = useState<string>(randomid(2));
	// const [editorPreferences, setEditorPreferences] = useState<object>({});

	// const monaco = useMonaco();
	const propsAuthResult = useSelector(authResult);
	const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
	const navigate = useNavigate();
	const editorRef = useRef(null);
	const editorWrapperRef = useRef(null);

	const { templateId } = useParams();

	const panes = {
		leftColumn: 'leftColumn',
		rightColumn: 'rightColumn',
		editorPane: 'editorPane',
		previewPane: 'previewPane',
	}

	useEffect(() => {
		if (propsAuthResult === authResults.failed) {
			navigate('/');
		}
	}, [propsAuthResult]);

	const propsTemplate = useSelector(propsTemplateData) || [];
	const status = useSelector(templateStatus);

	const unsaved = useMemo(() => {
		const localState = {
			...propsTemplate,
			template_name: templateName,
			template_html: templateHTML,
			template_data: templateData,
		};
		if (lodash.isEqual(localState, propsTemplate)) {
			return false;
		}
		return true;
	}, [templateName, templateHTML, templateData, propsTemplate]);

	useEffect(() => {

		const load = () => {
			dispatch(loadTemplate(templateId))
				.unwrap()
				.then(res => {
					setTemplateName(res?.template_name ?? '');
					setTemplateHTML(res?.template_html ?? '');
					setTemplateData(res?.template_data ?? {});
				})
				.catch(err => {
					promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
						open={show}
						onClose={onDismiss}
					>
						<DialogTitle>Failed to load the proposal template information</DialogTitle>
						<DialogContent>
							<DialogContentText>{err}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={onDismiss}>Cancel</Button>
							<Button onClick={onConfirm} autoFocus>Retry</Button>
						</DialogActions>
					</Dialog>
					)
						.then(load)
						.catch(() => {
							//
						});
				});
		};

		load();
	}, []);

	useBeforeunload(
		unsaved ? (event) => event.preventDefault() : null
	);


	const handleSave = () => {
		const data = {
			template_name: templateName,
			template_html: templateHTML,
			template_data: templateData,
		}
		dispatch(saveTemplate({ id: templateId, data }))
			.unwrap()
			.then(res => {
				promiseContainer(({ onDismiss, show }) => <Snackbar open={show} autoHideDuration={2000}
					onClose={onDismiss}
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					TransitionComponent={p => <Slide {...p} direction="left" />}
				>
					<Alert onClose={onDismiss} severity="success" sx={{ width: '100%' }}>Template saved</Alert>
				</Snackbar>
				)
					.catch(() => { });
			})
			.catch(err => {
				promiseContainer(({ onConfirm, show }) => <Dialog
					open={show}
					onClose={onConfirm}
				>
					<DialogTitle>Failed to save the template</DialogTitle>
					<DialogContent>
						<DialogContentText>{err}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={onConfirm}>Okay</Button>
					</DialogActions>
				</Dialog>
				);
			});
	};

	const resizeObserver = new ResizeObserver((entries) => {
		if (entries[0]) {
			const { width, height } = entries[0]?.contentRect;
			editorRef.current?.layout({ width, height });
		}
	});

	useEffect(() => {
		if (editorWrapperRef.current) {
			resizeObserver.observe(editorWrapperRef.current);
		}
	}, [editorWrapperRef.current]);

	const getLayoutState = () => {
		const item = window.localStorage.getItem("template-editor");

		if (item) {
			return JSON.parse(item);
		}

		return {
			[panes.leftColumn]: 700,
			[panes.rightColumn]: 300,
			[panes.editorPane]: 700,
			[panes.previewPane]: 300,
		}
	}

	const layoutState = getLayoutState();

	const onResizePane = (paneName: [string, string], sizes: [number, number]) => {
		if (sizes.length) {
			layoutState[paneName[0]] = sizes[0];
			layoutState[paneName[1]] = sizes[1];
			window.localStorage.setItem("template-editor", JSON.stringify(layoutState));
		}
	}

	// const _onResizePane = (event) => {
	// 	const { name, flex } = event.component.props;
	// 	layoutState[name].flex = flex;
	// 	window.localStorage.setItem("template-editor", JSON.stringify(layoutState));
	// }

	const handleEditorDidMount = (editor) => {
		editorRef.current = editor;

		if (editorRef.current) {
			const { width, height } = editorWrapperRef.current.getBoundingClientRect();
			editor.layout({ width, height });
		}

		editor.addAction({
			id: "ibdn.insert.textInput",
			label: "Insert Text Input",
			// An optional array of keybindings for the action.
			// keybindings: [
			// 	monaco.KeyMod.CtrlCmd | monaco.KeyCode.F10,
			// 	// chord
			// 	monaco.KeyMod.chord(
			// 		monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyK,
			// 		monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyM
			// 	),
			// ],
			precondition: null,
			keybindingContext: null,
			contextMenuGroupId: "edit",
			contextMenuOrder: 1.5,
			run: function (ed) {
				const key = 'text-' + randomid(4);
				let componentHTML = `<input type="text" data-key="${key}" />`;
				ed.getContribution("snippetController2").insert(componentHTML);
			},
		});

		editor.addAction({
			id: "ibdn.insert.textarea",
			label: "Insert Text Area",
			precondition: null,
			keybindingContext: null,
			contextMenuGroupId: "edit",
			contextMenuOrder: 1.5,
			run: function (ed) {
				const key = 'textarea-' + randomid(4);
				let componentHTML = `<textarea data-key="${key}"></textarea>`;
				ed.getContribution("snippetController2").insert(componentHTML);
			},
		});

		editor.addAction({
			id: "ibdn.insert.checkbox",
			label: "Insert Checkbox",
			precondition: null,
			keybindingContext: null,
			contextMenuGroupId: "edit",
			contextMenuOrder: 1.5,
			run: function (ed) {
				const key = 'checkbox-' + randomid(4);
				let componentHTML = `<input type="checkbox" data-key="${key}" />`;
				ed.getContribution("snippetController2").insert(componentHTML);
			},
		});

		editor.addAction({
			id: "ibdn.insert.signature",
			label: "Insert Signature Pad",
			precondition: null,
			keybindingContext: null,
			contextMenuGroupId: "edit",
			contextMenuOrder: 1.5,
			run: function (ed) {
				const key = 'signature-' + randomid(4);
				let componentHTML = `<s type="signature" data-key="${key}"></s>`;
				ed.getContribution("snippetController2").insert(componentHTML);
			},
		});
	}

	const debouncedChangeCachedTemplate = lodash.debounce((code: string) => {
		setCachedTemplateHTML(code);
	}, 1000);

	useEffect(() => {
		debouncedChangeCachedTemplate(templateHTML);
		return debouncedChangeCachedTemplate.cancel;
	}, [templateHTML]);

	const handleCodeChange = (code: string) => {
		setTemplateHTML(code);
	}

	const beforeNavigateAway = () => {
		const goBack = () => {
			navigate(`/templates/${window.location.search || ''}`, { replace: true });
		};

		if (unsaved) {
			promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
				open={show}
				onClose={onDismiss}
			>
				<DialogTitle>Leave the template editor?</DialogTitle>
				<DialogContent>
					<DialogContentText>Changes that you made haven not been saved.</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onDismiss} autoFocus>Cancel</Button>
					<Button onClick={onConfirm} color="error">Leave</Button>
				</DialogActions>
			</Dialog>
			)
				.then(() => {
					goBack();
				})
				.catch(() => { });
		} else {
			goBack();
		}
	}

	return (
		<Stack direction="column" height="100%">

			<Box sx={{ flexGrow: 0, flexShrink: 0, zIndex: 1200 }}>
				<AppBar sx={{ position: 'static' }} elevation={0}>
					<Toolbar variant="dense">

						<Stack sx={{ flexGrow: 1 }} direction="row" alignItems="center">

							<IconButton
								edge="start"
								color="inherit"
								sx={{ flexGrow: 0 }}
								onClick={beforeNavigateAway}
							>
								<IconArrowBack />
							</IconButton>

							<Stack flexDirection="row">
								<Typography
									sx={{
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										maxWidth: '360px',
									}}
								>
									Edit Template
								</Typography>
							</Stack>
						</Stack>

						<React.Fragment>
							<Badge color="warning" variant="dot" invisible={!unsaved || status === statuses.loading}>
								<Button color="white" onClick={handleSave} disabled={status === statuses.saving}>
									{
										status === statuses.saving ?
											<CircularProgress size="1.25rem" color="white" sx={{ mr: .5 }} />
											:
											<IconSave sx={{ mr: 1 }} />
									}
									Save
								</Button>
							</Badge>
						</React.Fragment>

					</Toolbar>
				</AppBar>
			</Box>

			<Allotment onChange={sizes => onResizePane([panes.leftColumn, panes.rightColumn], sizes as [number, number])}>

				<Allotment.Pane preferredSize={layoutState[panes.leftColumn]}>

					<Allotment vertical onChange={sizes => onResizePane([panes.editorPane, panes.previewPane], sizes as [number, number])}>

						<Allotment.Pane preferredSize={layoutState[panes.editorPane]}>
							<Box sx={{ width: '100%', height: '100%', overflow: 'hidden' }} ref={editorWrapperRef}>
								<Editor
									width="100%"
									height="100%"
									defaultLanguage="html"
									options={editorOptions}
									value={templateHTML ?? "<!-- Edit the content to start -->"}
									theme="vs-dark"
									onMount={handleEditorDidMount}
									onChange={code => handleCodeChange(code as string)}
									ref={editorRef}
								/>
							</Box>
						</Allotment.Pane>

						<Allotment.Pane preferredSize={layoutState[panes.previewPane]}>
							<Box sx={{ overflow: 'auto', width: '100%', height: '100%', backgroundColor: colors.grey[300] }}>

								<Container maxWidth="md" sx={{ py: 4 }}>
									<Paper elevation={8} square>
										<DocumentViewer
											key={documentViewerKey}
											htmlTemplate={cachedTemplateHTML || "<div class='text-center text-muted'>Edit the code to preview</div>"}
											data={templateData || {}}
											onChange={setTemplateData}
											jsonDidAutoComplete={setTemplateData}
										/>
									</Paper>
								</Container>

							</Box>
						</Allotment.Pane>

					</Allotment>

				</Allotment.Pane>

				<Allotment.Pane preferredSize={layoutState[panes.rightColumn]}>
					<Box sx={{ width: '100%', height: '100%', overflow: 'auto', backgroundColor: colors.grey.A100 }}>
						<Stack direction="column">
							<Box sx={{ pt: 2, px: 2 }}>
								<OutlinedInput
									placeholder="Template Name"
									size="small"
									value={templateName}
									onChange={e => setTemplateName(e.target.value)}
									// sx={{ backgroundColor: colors.common.white }}
									fullWidth
								/>
							</Box>

							<Box sx={{ mt: 2 }}>
								<Stack direction="row" justifyContent="start" alignItems="center" sx={{ mx: 2 }}>
									<IconTipsAndUpdates fontSize="small" sx={{ mr: .5 }} />
									<Typography sx={{ my: 0, color: colors.grey[700] }}>Tips</Typography>
								</Stack>

								<Stack sx={{ px: 2, py: 1, mt: 1, backgroundColor: colors.common.white }}>
									<ul style={{ fontSize: '.875rem', paddingLeft: '1.5rem', margin: 0 }}>
										<li>No <code>{'<p></p>'}</code> tag is allowed in the template HTML.</li>
										<li>Make sure there is no same <code>data-key</code> in the document.</li>
									</ul>
								</Stack>
							</Box>

							{/* <Box sx={{ pt: 2, px: 2 }}>
								<Typography variant="caption" sx={{ color: colors.grey[700] }}>Editor Preferences</Typography>
								<Stack>
									<FormControlLabel control={<Switch defaultChecked />} label="Vim Keymap" />
									<FormControlLabel control={<Switch defaultChecked />} label="Word Wrap" />
								</Stack>
							</Box> */}

							<Box sx={{ mt: 2 }}>
								<Stack direction="row" justifyContent="space-between" alignItems="center">

									<Stack direction="row" alignItems="center" sx={{ mx: 2 }}>
										<IconDataObject fontSize="small" sx={{ mr: .5 }} />
										<Typography sx={{ my: 0, color: colors.grey[700] }}>JSON</Typography>
									</Stack>

									<IconButton onClick={() => {
										setTemplateData({});
										setDocumentViewerKey(randomid(2))
									}}><IconSync fontSize="small" /></IconButton>
								</Stack>

								<Box sx={{ px: 2, py: 1, backgroundColor: colors.common.white }}>
									<JSONViewWrapper>
										<ReactJson
											src={templateData}
											displayDataTypes={false}
											displayObjectSize={false}
											indentWidth={2}
											// enableClipboard={false}
											name={false}
										/>
									</JSONViewWrapper>
								</Box>
							</Box>
						</Stack>
					</Box>
				</Allotment.Pane>

			</Allotment>

		</Stack>
	);
};

export default TemplateEdit;

TemplateEdit.displayName = 'TemplateEdit';

const editorOptions = {
	"acceptSuggestionOnCommitCharacter": true,
	"acceptSuggestionOnEnter": "on",
	"accessibilitySupport": "auto",
	"autoIndent": false,
	"automaticLayout": false,
	"codeLens": true,
	"colorDecorators": true,
	"contextmenu": true,
	"cursorBlinking": "blink",
	"cursorSmoothCaretAnimation": false,
	"cursorStyle": "line",
	"disableLayerHinting": false,
	"disableMonospaceOptimizations": false,
	"dragAndDrop": false,
	"fixedOverflowWidgets": false,
	"folding": true,
	"foldingStrategy": "auto",
	"fontLigatures": false,
	"formatOnPaste": false,
	"formatOnType": false,
	"hideCursorInOverviewRuler": false,
	"highlightActiveIndentGuide": true,
	"links": true,
	"mouseWheelZoom": false,
	"multiCursorMergeOverlapping": true,
	"multiCursorModifier": "alt",
	"overviewRulerBorder": true,
	"overviewRulerLanes": 2,
	"quickSuggestions": true,
	"quickSuggestionsDelay": 100,
	"readOnly": false,
	"renderControlCharacters": false,
	"renderFinalNewline": 'off',
	"renderIndentGuides": true,
	"renderLineHighlight": "all",
	"renderWhitespace": "none",
	"revealHorizontalRightPadding": 30,
	"roundedSelection": true,
	"rulers": [],
	"scrollBeyondLastColumn": 5,
	"scrollBeyondLastLine": true,
	"selectOnLineNumbers": true,
	"selectionClipboard": true,
	"selectionHighlight": true,
	"showFoldingControls": "mouseover",
	"smoothScrolling": false,
	"suggestOnTriggerCharacters": true,
	"wordBasedSuggestions": true,
	"wordSeparators": "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
	"wordWrap": "off",
	"wordWrapBreakAfterCharacters": "\t})]?|&,;",
	"wordWrapBreakBeforeCharacters": "{([+",
	"wordWrapBreakObtrusiveCharacters": ".",
	"wordWrapColumn": 80,
	"wordWrapMinified": true,
	"wrappingIndent": "none",
	"tabSize": 2,
};

const JSONViewWrapper = styled.div`
*{
	font-size: 11px !important;
}
`;